
export function makeDisplayMvtsLink(fiscalYearId: string,
                                    fromDate: string,
                                    toDate: string,
                                    fromAccount: string,
                                    toAccount: string,
                                    ccyCode: string,
                                    withZeroAccounts: boolean,
                                    withInitBal: boolean,
                                    withMovements: boolean,
                                    withProvisionalEntries: boolean = true,
                                    debits: boolean = true,
                                    credits: boolean = true) {
  const { protocol, host, pathname } = window.location;
  let url = `${protocol}//${host}${pathname}`;
  url = url.slice(0, url.indexOf('/taskpane.html'))

  return `${url}/app/app.html#/displaymvts?fiscalYearId=${fiscalYearId}&fromDate=${fromDate}&toDate=${toDate}&fromAccount=${fromAccount}&toAccount=${toAccount}&ccyCode=${ccyCode}&withZeroAccounts=${withZeroAccounts}&withInitBal=${withInitBal}&withMovements=${withMovements}&withProvisionalEntries=${withProvisionalEntries}&debits=${debits}&credits=${credits}`;
}
