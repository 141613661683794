import { AccountGroupingType, AccountNature, PublicTrialBalanceTreeListDTO } from "@taskpane/types";

import { BalanceSheetPLBase, TotalCellRanges } from "./balanceSheetPLBase";

import i18n from "i18next";

export default class ProfitLoss extends BalanceSheetPLBase {
  private _costCell: string;
  private _incomeCell: string;

  protected async onProcessTotalCellRanges(tots: TotalCellRanges) {
    if (tots.groupingType === AccountGroupingType.Class) {
      const sh = this._context.workbook.worksheets.getActiveWorksheet();
      switch (tots.nature) {
        case AccountNature.Cost: {
          const cell = sh.getCell(this.CurLine, this.CurCol + 4);
          cell.load("address");
          await this._context.sync();
          this._costCell = cell.address;
          break;
        }
        case AccountNature.Income: {
          const cell = sh.getCell(this.CurLine, this.CurCol + 4);
          cell.load("address");
          await this._context.sync();
          this._incomeCell = cell.address;
          break;
        }
      }
    }
  }

  async generate(
    list: Array<PublicTrialBalanceTreeListDTO>,
    fyCode: string,
    fyDesc: string,
    dateFrom: Date,
    dateTo: Date,
    withOrigCurrency: boolean,
    refCurrency: string,
    excelContext: Excel.RequestContext
  ): Promise<void> {
    await super.generate(list, fyCode, fyDesc, dateFrom, dateTo, withOrigCurrency, refCurrency, excelContext);
    const sh = this._context.workbook.worksheets.getActiveWorksheet();

    if (!this._costCell || !this._incomeCell) return;

    this.CurLine++;
    let cell = sh.getCell(this.CurLine, this.CurCol + 4);
    cell.load("address");
    await this._context.sync();

    const resultCellAddress = cell.address;
    cell.formulas = [[`=-(${this._costCell}+${this._incomeCell})`]];

    cell = sh.getCell(this.CurLine, this.CurCol);
    cell.formulas = [[`=IF(${resultCellAddress} < 0, "${i18n.t("loss")}","${i18n.t("profit")}")`]];
    cell.format.font.bold = true;
  }
}
