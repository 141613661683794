import { AnyAction, CombinedState, configureStore, Middleware } from "@reduxjs/toolkit";
import { rootReducer, StoreState } from "@taskpane/core/reducers";
import { rootSaga } from "@taskpane/core/sagas";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import logger from "redux-logger";
import { PersistConfig, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

const { NODE_ENV } = process.env;

const sagaMiddleware = createSagaMiddleware();

const persistConfig: PersistConfig<CombinedState<StoreState>, AnyAction> = {
  key: "gefwifuhoegfog",
  storage,
  blacklist: ['main'],
};

const middleware: Middleware[] = [sagaMiddleware];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const isDev = NODE_ENV === "development";

if (isDev) {
  middleware.push(logger);
}

export function initStore() {
  const store = configureStore({
    reducer: persistedReducer,
    middleware,
    devTools: isDev
  });
  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);

  return { store, persistor };
}

const { persistor, store } = initStore();
export { persistor, store };

export type AppState = ReturnType<typeof store.getState>;
export type RootState = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<RootState>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
