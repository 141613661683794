// images references in the manifest
import "../../../assets/icon-16.png";
import "../../../assets/icon-32.png";
import "../../../assets/icon-64.png";
import "../../../assets/icon-80.png";
import "../../../assets/icon-128.png";
import "../../../assets/logo-filled.png";
import "../../../assets/logoGit.png";

import { SpinnerSize } from "@fluentui/react";
import { Loader } from "@taskpane/components";
import { Routes } from "@taskpane/routes";
import React, {useEffect} from "react";
import { HashRouter as TaskPaneRouter } from "react-router-dom";
import {Services} from "./services.context";
export interface AppProps {
  isOfficeInitialized: boolean;
}

export const App = ({ isOfficeInitialized }: AppProps) => {
  if (!isOfficeInitialized) {
    return (
      <div className="h-100 d-flex flex-column">
        <Loader loader="spinner" size={SpinnerSize.large} className="align-self-center" />
      </div>
    );
  }

  return (
    <TaskPaneRouter basename="/taskpane">
      <Services>
        <Routes />
      </Services>
    </TaskPaneRouter>
  );
};
