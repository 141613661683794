import "./styles.css";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "../../organisms";
import { GLBalanceWizard } from "../glbalancewizard";
import { GLRangeWizard } from "../glrangewizard";

enum FormulaType {
  ftList,
  ftGLBalance,
  ftGLRange,
}

export const FormulasList: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [currentFormula, setCurrentFormula] = useState(FormulaType.ftList);
  const glRangeFormulaRef = useRef();
  const glBalanceFormulaRef = useRef();

  const onClickBackButton = () => {
    setCurrentFormula(FormulaType.ftList);
  };

  async function onGLBalanceWizardDisplay() {
    setCurrentFormula(FormulaType.ftGLBalance);
  }

  async function onGLRangeWizardDisplay() {
    setCurrentFormula(FormulaType.ftGLRange);
  }

  switch (currentFormula) {
    case FormulaType.ftList:
      return (
        <div className="pt-4">
          <div className="mt-1 mb-2 ms-fontWeight-bold">
            <span>{t("formulas")}</span>
          </div>
          <Card className="card-formula mb-4" onClick={onGLBalanceWizardDisplay}>
            <div className="card-body">
              <h5 className="card-title">{t("glBalanceFormula")}</h5>
              <p className="card-text">{t("glBalanceFormulaDesc")}</p>
            </div>
          </Card>
          <Card className="card-formula mb-4" onClick={onGLRangeWizardDisplay}>
            <div className="card-body">
              <h5 className="card-title">{t("glRangeFormula")}</h5>
              <p className="card-text">{t("glRangeFormulaDesc")}</p>
            </div>
          </Card>
        </div>
      );
    case FormulaType.ftGLBalance:
      return <GLBalanceWizard ref={glBalanceFormulaRef} onBackClick={() => onClickBackButton()}></GLBalanceWizard>;
    case FormulaType.ftGLRange:
      return <GLRangeWizard ref={glRangeFormulaRef} onBackClick={() => onClickBackButton()}></GLRangeWizard>;
  }
};
