import {MainActions} from "@taskpane/core/actions";
import {MainActionTypes} from "@taskpane/core/actionTypes";
import {MainStateInfos} from "@taskpane/types";

const initialMainState: MainStateInfos = {
  displayFormulas: false,
  companyList: [],
  accountList: []
};

export const getMainStateSelector = state => state.main;

export function mainReducer(state: MainStateInfos = initialMainState, action: MainActions): MainStateInfos {
  switch (action.type) {
    case MainActionTypes.UPDATE_DISPLAY_FORMULAS:
      return {
        ...state,
        displayFormulas: action.payload
      }
    case MainActionTypes.LOAD_COMPANY_LIST:
      return {
        ...state
      }
    case MainActionTypes.COMPUTE_FORMULAS:
      return { ...state };
    case MainActionTypes.SET_COMPANY_LIST:
      return {
        ...state,
        companyList: action.payload
      }
    case MainActionTypes.LOAD_ACCOUNT_LIST:
      return {
        ...state
      }
    case MainActionTypes.SET_ACCOUNT_LIST:
      return {
        ...state,
        accountList: action.payload
      }
    case MainActionTypes.LOAD_CURRENCY_LIST:
      return {
        ...state
      }
    case MainActionTypes.SET_CURRENCY_LIST:
      return {
        ...state,
        currencyList: action.payload
      }
    default:
      return state;
  }
}
