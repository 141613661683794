import { ApiService } from "@taskpane/core/services";
import moment from "moment-msdate";

import { IApiService } from "../core/services/interfaces/IAPI.service";
import IFormulaBase from "./formulaBase";
import {makeDisplayMvtsLink} from "@taskpane/utils";

export interface GLBalanceFormulaData {
  companyId: string;
  account: string;
  currency: string;
  fromDate: string;
  toDate: string;
  withProvisionalEntries: string;
  originalCurrency: string;
  debits: string;
  credits: string;
}

export class GLBalanceFormula implements IFormulaBase {
  constructor(private apiService: IApiService) {}

  private name = "PMGLBalance";
  formulaData: GLBalanceFormulaData;

  generate(): string {
    const data = this.formulaData;
    return `#${this.name}/${data.companyId}/${data.account}/${data.currency}/{${data.fromDate}}/{${data.toDate}}/${data.withProvisionalEntries}/${data.originalCurrency}/${data.debits}/${data.credits}`;
  }

  async getAmount(): Promise<number | string> {
    const data = this.formulaData;
    const resHttp = await this.apiService.getTrialBalance(
      data.companyId,
      data.fromDate,
      data.toDate,
      data.account,
      data.account,
      data.currency,
      true,
      true,
      true,
      data.originalCurrency === "true",
      data.withProvisionalEntries === "true",
      data.debits === "true",
      data.credits === "true",
    );
    if (resHttp.ok) {
      return resHttp.parsedBody;
    } else {
      return "error while fetching amount";
    }
  }

  parse(value: string, apiService?: IApiService): IFormulaBase {
    if (!isNaN(<any>value)) return null;
    if (!value) return null;
    if (!value.startsWith("#" + this.name)) return null;
    const formulaInst = new GLBalanceFormula(apiService !== undefined ? apiService : new ApiService());

    let tvalue = value;
    let match = tvalue.match(/\{[0-9,/-]*\}/);

    while (match && match.length > 0) {
      const concatValue = match[0]
        .substring(1, match[0].length - 1);

      tvalue =
        tvalue.substring(0, tvalue.indexOf(match[0])) +
        (!isNaN(<any>concatValue) ? concatValue :
          concatValue.replace(/\//g, "dateSep")
            .replace(",", ".")) +
        tvalue.substring(tvalue.indexOf(match[0]) + match[0].length);
      match = tvalue.match(/\{[0-9,/-]*\}/);
    }

    const values = tvalue.split("/");
    const fromDateString = !isNaN(<any>values[4]) ? values[4] : values[4].replace(/dateSep/g, "/");
    const toDateString = !isNaN(<any>values[5]) ? values[5] : values[5].replace(/dateSep/g, "/");

    formulaInst.formulaData = {
      companyId: values[1],
      account: values[2],
      currency: values[3],
      fromDate: !isNaN(<any>fromDateString) ? moment.fromOADate(fromDateString).format("MM-DD-YYYY") : fromDateString,
      toDate: !isNaN(<any>toDateString) ? moment.fromOADate(toDateString).format("MM-DD-YYYY") : toDateString,
      withProvisionalEntries: values[6].toLowerCase(),
      originalCurrency: values[7].toLowerCase(),
      debits: values.length > 8 ? values[8].toLowerCase() : "true",
      credits: values.length > 9 ? values[9].toLowerCase() : "true",
    };
    return formulaInst;
  }

  getDisplayMvtsLink(): string {
    return makeDisplayMvtsLink(this.formulaData.companyId,
      this.formulaData.fromDate,
      this.formulaData.toDate,
      this.formulaData.account,
      this.formulaData.account,
      this.formulaData.currency,
      true,
      true,
      true,
      this.formulaData.withProvisionalEntries === 'true',
      this.formulaData.debits === 'true',
      this.formulaData.credits === 'true');
  }

  async getMvts() {
    try {
      return await this.apiService.getTrialBalanceMvt(
        this.formulaData.companyId,
        this.formulaData.fromDate,
        this.formulaData.toDate,
        this.formulaData.account,
        this.formulaData.account,
        this.formulaData.currency,
        true,
        true,
        true,
        this.formulaData.withProvisionalEntries === 'true',
        this.formulaData.debits === 'true',
        this.formulaData.credits === 'true'
      );
    } catch (e) {
      console.error('glBalanceFormula.getMvts ', e);
      throw new Error(e);
    }
  }
}
