import { Dialog, DialogFooter, DialogType, ProgressIndicator, Toggle } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { loadCompanyList, updateDisplayFormulas } from "@taskpane/core/actionCreators";
import { ApiService } from "@taskpane/core/services";
import { useAppDispatch, useAppSelector } from "@taskpane/core/store";
import { FormulasMode, setApiURL } from "@taskpane/utils";
import { throttle } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ViewsEndpoints } from "../../../core/endpoints";
import ChoiceFormulaWrapper from "../../../features/choiceFormulaWrapper";
import ComputeFormulas from "../../../features/computeFormulas";
import FormulasModeClass from "../../../features/formulasModeClass";
import { GLBalanceFormula } from "../../../features/glBalanceFormula";
import { Button } from "../../atoms";

let currentCellFormula = null;
const cancellableComputeJob = { cancel: null, cancelled: false};

export const ComputePage: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [isComputing, setIsComputing] = useState(true);
  const [currentSheetIdentifier, setCurrentSheetIdentifier] = useState(null);
  const [percentComplete, setPercentComplete] = useState(0);
  const dispatch = useAppDispatch();
  const { companyList } = useAppSelector((state) => state.main);


  const { displayFormulas } = useAppSelector((state) => state.main);
  const [viewCellDetailsEnabled, setViewCellDetailsEnabled] = useState(false);

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  useEffect(() => {
    if (isComputing) {
      if (companyList.length === 0) dispatch(loadCompanyList(true));

      const compute = new ComputeFormulas();
      let maxProgress = 0;
      const throttledProgress = throttle((current) => {
        setPercentComplete(current / maxProgress);
      }, 400);

      Excel.run(async (context) => {
        try {
          cancellableComputeJob.cancelled = false;
          await compute.compute(
            displayFormulas ? FormulasMode.Formulas : FormulasMode.Amount,
            (max, currentSheetIdentifier1) => {
              maxProgress = max;
              setCurrentSheetIdentifier(currentSheetIdentifier1);
            },
            (current) => {
              throttledProgress(current);
            },
            () => {
              setPercentComplete(0);
              setCurrentSheetIdentifier(null);
              setIsComputing(false);
            },
            cancellableComputeJob,
            context
          );
        } catch (e) {
          console.error("ComputePage ", e);
        }
      });
    }
  }, [companyList.length, dispatch, displayFormulas, isComputing]);


  function onStopClick() {
    setIsComputing(false);
    cancellableComputeJob.cancel();
  }

  useEffect(() => {
    Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, async function () {
      await Excel.run(async (context) => {
        const cell = context.workbook.getActiveCell();
        cell.load("formulas");
        await context.sync();
        currentCellFormula = cell.formulas.length > 0 && cell.formulas[0].length > 0 ? cell.formulas[0][0] : null;
        const w = new ChoiceFormulaWrapper();
        const unwrappedFormula = w.unwrap(currentCellFormula);
        if (unwrappedFormula) {
          const f = new GLBalanceFormula(new ApiService());
          if (f.parse(unwrappedFormula.formula)) setViewCellDetailsEnabled(true);
        }
      });
    });
  }, []);

  async function onViewCellDetailsClick() {
    const w = new ChoiceFormulaWrapper();
    const unwrappedFormula = w.unwrap(currentCellFormula);
    if (!unwrappedFormula) {
      console.error("onViewCellDetailsClick: could not unwrap formula ", currentCellFormula);
      return;
    }
    const f = new GLBalanceFormula(new ApiService());
    const formulaObj = f.parse(unwrappedFormula.formula);
    if (!formulaObj) {
      console.error("could not parse formula ", unwrappedFormula.formula);
      return;
    }

    if (companyList.length === 0) dispatch(loadCompanyList(true));

    const fData = (formulaObj as GLBalanceFormula).formulaData;
    const company = companyList.find((e) => e.FiscalYearDescription === fData.companyId);
    if (!company) {
      console.error("onViewCellDetailsClick : could not find company ", fData.companyId);
      return;
    }

    const apiService = new ApiService();
    const coa = await apiService.getCOA(company.ID);
    if (!coa || !coa.ok) {
      console.error("onViewCellDetailsClick: could not load COA with company id ", company.ID);
      return;
    }

    const acc = coa.parsedBody.find((e) => e.AccountNumber === fData.account);
    if (!acc) {
      console.error("onViewCellDetailsClick: could not load account ", fData.account);
      return;
    }

    await Office.context.ui.displayDialogAsync(
      setApiURL(
        ViewsEndpoints.getAccountMovements(
          acc.ID,
          new Date(fData.fromDate),
          new Date(fData.toDate),
          fData.withProvisionalEntries
        )
      ),
      null,
      (result) => {
        console.log(result);
      }
    );
  }

  const dialogContentProps = {
    type: DialogType.normal,
    title: t("confirmationTitle"),
    closeButtonAriaLabel: t("close"),
    subText: t("confirmationText"),
  };

  const labelId: string = useId("dialogLabel");
  const subTextId: string = useId("subTextLabel");

  const dialogStyles = { main: { maxWidth: 450 } };

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: undefined,
    }),
    [labelId, subTextId]
  );

  const rest = (
    <>
      <hr style={{ margin: "20px 0" }}></hr>

      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}

        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={() => { toggleHideDialog(); setIsComputing(true) } } text="OK" />
          <DefaultButton onClick={toggleHideDialog} text={t("cancel")} />
        </DialogFooter>
      </Dialog>
      {/*<a href="#" onClick={onViewCellDetailsClick}>t("displayDetails")</a>*/}
    </>
  );
  if (isComputing) {
    return (
      <div className="pt-4">
        <div className="mt-1 mb-2 ms-fontWeight-bold">
          <span>{t("computePage")}</span>
        </div>
        <ProgressIndicator
          barHeight={10}
          description={currentSheetIdentifier}
          percentComplete={percentComplete}
        ></ProgressIndicator>
        <Button label={t("stop")} onClick={() => onStopClick()}></Button>
        {rest}
      </div>
    );
  } else {
    return (
      <div className="pt-4">
        <div className="mt-1 mb-2 ms-fontWeight-bold">
          <span>{t("computePage")}</span>
        </div>
        <Button label={t("compute")} onClick={() => setIsComputing(true)}></Button>
        {rest}
      </div>
    );
  }
};
