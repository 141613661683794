import { DefaultButton, IButtonProps, PrimaryButton } from "@fluentui/react/lib/Button";
import React, { memo, ReactNode } from "react";

type ButtonType = "primary" | "default" | undefined;

export interface ButtonProps extends IButtonProps {
  color?: ButtonType;
  label?: string;
  children?: ReactNode;
}

export const Button = ({ color, label, styles, children, ...rest }: ButtonProps) => {
  switch (color) {
    case undefined:
      return (
        <DefaultButton styles={{ root: { width: "100%" }, ...styles }} {...rest}>
          {label || children}
        </DefaultButton>
      );
    case "default":
      return (
        <DefaultButton styles={{ root: { width: "100%" }, ...styles }} {...rest}>
          {label || children}
        </DefaultButton>
      );
    case "primary":
      return (
        <PrimaryButton styles={{ root: { width: "100%" }, ...styles }} {...rest}>
          {label || children}
        </PrimaryButton>
      );
    default:
      return (
        <DefaultButton styles={{ root: { width: "100%" }, ...styles }} {...rest}>
          {label || children}
        </DefaultButton>
      );
  }
}
