import {FormulasMode} from "@taskpane/utils";

export default class ChoiceFormulaWrapper {
  /**
   * wrap a custom formula and an amount to a =CHOOSE excel formula
   * @param mode
   * @param amount
   * @param formula
   */
  wrap(mode: FormulasMode, amount: number, formula : string) {
    return `=CHOOSE(${mode.toString()},${amount ? amount.toString() : amount},"${formula}")`;
  }

  /**
    eg. input "=CHOOSE(1, chiffreComptable, formula)"
   @param wrappedFormula The wrapped formula to unwrap
   @returns an object containing the value and formula of the =CHOICE wrapped formula
   */
  unwrap(wrappedFormula: string): { value: number, formula: string, mode: FormulasMode } {
    if (!wrappedFormula) return null;
    if (!isNaN(<any>wrappedFormula)) return null;
    if (!wrappedFormula.startsWith("=CHOOSE")) return null;

    const split = wrappedFormula.split(",");
    if (split.length == 3) {
      let formula = split[2].substring(0, split[2].length - 1).trim(); // remove end parenthesis
      formula = formula.substring(1, formula.length - 1);
      const value = split[1].trim();
      return { mode: parseInt(split[0].trim().split("(")[1], 10), formula: formula, value: parseFloat(value) };
    }
    else return null;
  }
}
