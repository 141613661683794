import {
  GetApiKeyAction,
  GetApiKeySuccessAction,
  SignInAction,
  SignInFailureAction,
  SignInRequestAction,
  SignInSuccessAction,
  SignOutAction,
} from "@taskpane/core/actions";
import { AuthActionTypes } from "@taskpane/core/actionTypes";
import { AuthStateInfos } from "@taskpane/types";

export function signIn(payload: boolean): SignInAction {
  return {
    type: AuthActionTypes.SIGN_IN,
    payload,
  };
}

export function signInRequest(): SignInRequestAction {
  return {
    type: AuthActionTypes.SIGN_IN_REQUEST,
  };
}

export function signInSuccess(auth: AuthStateInfos): SignInSuccessAction {
  return {
    type: AuthActionTypes.SIGN_IN_SUCCESS,
    payload: { auth },
  };
}

export function signInFailure(error: Error | null): SignInFailureAction {
  return {
    type: AuthActionTypes.SIGN_IN_FAILURE,
    payload: { error },
  };
}

export function signOut(): SignOutAction {
  return {
    type: AuthActionTypes.SIGN_OUT,
  };
}

export function getApiKey(payload: boolean): GetApiKeyAction {
  return {
    type: AuthActionTypes.GET_API_KEY,
    payload,
  };
}

export function getApiKeySuccess(auth: AuthStateInfos): GetApiKeySuccessAction {
  return {
    type: AuthActionTypes.GET_API_KEY_SUCCESS,
    payload: { auth },
  };
}
