import {GridCellProps} from "@progress/kendo-react-grid";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilePdf} from "@fortawesome/free-regular-svg-icons";
import {buttonStyle} from "./style";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const GridCellPdfIcon: React.FunctionComponent<GridCellProps & {onPdfClick }> = (props: GridCellProps & { onPdfClick } ) => {
  return(
    <td>
      {props.dataItem.StorageItems.length === 1 &&
        <button className={buttonStyle} onClick={(e) => { e.stopPropagation(); props.onPdfClick(e, props.dataItem.StorageItems[0].ID, props.dataItem); }}>
          <FontAwesomeIcon style={{color: 'red'}} icon={faFilePdf as IconProp} />
        </button>}
      {props.dataItem.StorageItems.length > 1 &&
        <button className={buttonStyle} onClick={(e) => { e.stopPropagation(); props.onPdfClick(e, null, props.dataItem); }}>
            <FontAwesomeIcon style={{color: 'red'}} icon={faFilePdf as IconProp} />
        </button>}
    </td>
  )
}
