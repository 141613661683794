import { ApiService } from "@taskpane/core/services";
import {
  AccountGroupingType,
  AccountNature,
  PublicCOATreeListDTO,
  PublicTrialBalanceTreeListDTO,
} from "@taskpane/types";
import { FormulasMode } from "@taskpane/utils";
import moment from "moment-msdate";

import { Stack } from "../../tools/utils/stack";
import ChoiceFormulaWrapper from "../choiceFormulaWrapper";
import GLRangeFormula from "../glRangeFormula";
import { Helper } from "../helpers/helper";

export type ProcessTotalsCallback = (tots: TotalCellRanges) => void;
export class TotalCellRange {
  public get valid(): boolean {
    return !!this.coordStart && !!this.coordEnd;
  }

  constructor(public coordStart: string, public coordEnd: string) {}

  getExcelRange(): string {
    return `${this.coordStart}:${this.coordEnd}`;
  }
}

export class TotalCellRanges {
  currentRangeIndex: number;
  stop: boolean;
  private _cellRanges: Array<TotalCellRange> = [];
  public get cellRanges(): Array<TotalCellRange> {
    return this._cellRanges;
  }
  private readonly _nature: AccountNature;
  public get nature(): AccountNature {
    return this._nature;
  }
  private readonly _groupingType: AccountGroupingType;
  public get groupingType(): AccountGroupingType {
    return this._groupingType;
  }

  public get currentCellRange(): TotalCellRange {
    if (this.currentRangeIndex >= 0 && this.currentRangeIndex <= this.cellRanges.length - 1) {
      return this.cellRanges[this.currentRangeIndex];
    } else {
      return this.newTotalCellRange("", "");
    }
  }

  public newTotalCellRange(coord1: string, coord2: string): TotalCellRange {
    this.currentRangeIndex++;
    const newOne = new TotalCellRange(coord1, coord2);
    this.cellRanges.push(newOne);
    return newOne;
  }

  constructor(nature: AccountNature, groupingType: AccountGroupingType) {
    this._nature = nature;
    this._groupingType = groupingType;
    this.reset();
  }

  public reset(): void {
    this.currentRangeIndex = -1;
    this._cellRanges = [];
  }
}

export enum BalanceSheetPLError {
  noError = 0,
  // could not detect assets and liabilities cells during
  // processing, most likely there is a structure error in the
  // company (eg. class 2 is assets and should be liabilities)
  structureError,
}

export abstract class BalanceSheetPLBase {
  CurLine: number;
  CurCol: number;
  private cellCache: Map<string, Excel.Range>;

  private storeOrGetFromCache(sh: Excel.Worksheet, line: number, col: number, loadPropList: string) {
    const key = `${col}-${line}`;
    if (this.simulationMode) {
      const cell = sh.getCell(line, col);
      cell.load(loadPropList);
      this.cellCache.set(key, cell);
      return cell;
    } else {
      return this.cellCache.get(key);
    }
  }
  protected FyCode: string;
  protected DateFromCellRange: string;
  protected DateToCellRange: string;
  protected WithOrigCcy: boolean;
  protected RefCurrencyCode: string;
  protected formulasMode: FormulasMode = FormulasMode.Amount;
  protected DateFrom: Date;
  protected DateTo: Date;

  protected Totals: Map<string, TotalCellRanges> = new Map<string, TotalCellRanges>();

  protected _context: Excel.RequestContext;

  public lastError: BalanceSheetPLError = BalanceSheetPLError.noError;

  /**
   * controls if we're gathering cells coordinates to do a single context.sync() or if we're computing
   */
  public simulationMode: boolean;

  protected abstract onProcessTotalCellRanges(tots: TotalCellRanges);

  private async refreshTotals(sh: Excel.Worksheet) {
    for (const entry of Array.from(this.Totals.entries())) {
      const key = entry[0];
      const value = entry[1];

      if (value.stop) continue;

      const cur = value.currentCellRange;

      let curCoord: string = null;
      const cell = this.storeOrGetFromCache(sh, this.CurLine, this.CurCol + 4, "address");
      if (!this.simulationMode) {
        curCoord = cell.address;

        let coordStart: string;
        if (cur.coordStart) coordStart = cur.coordStart;
        else coordStart = curCoord;

        value.currentCellRange.coordStart = coordStart;
        value.currentCellRange.coordEnd = curCoord;
      }
    }
  }

  protected StoreTotal(cur: string, nature: AccountNature, groupingType: AccountGroupingType) {
    if (!this.Totals.has(cur)) {
      this.Totals.set(cur, new TotalCellRanges(nature, groupingType));
    }

    for (const entry of Array.from(this.Totals.entries())) {
      entry[1].newTotalCellRange("", "");
    }
  }

  protected async OutputTotals(sh: Excel.Worksheet, cur: PublicCOATreeListDTO, onProcessTotals: ProcessTotalsCallback) {
    const keyList = Array.from(this.Totals.entries());
    for (let i = keyList.length - 1; i >= 0; i--) {
      const key = keyList[i][0];
      const tots = keyList[i][1];

      if (cur && key.length < cur.AccountNumber.length && cur.AccountNumber.startsWith(key)) {
        continue;
      }

      let cell = sh.getCell(this.CurLine, this.CurCol);
      cell.values = [[`'${key}`]];
      cell.format.font.bold = true;
      cell = sh.getCell(this.CurLine, this.CurCol + 2);
      cell.values = [["Total"]];
      cell.format.font.bold = true;

      let formula: string = null;
      let outputTotals = false;
      for (const tot of tots.cellRanges) {
        if (!tot.valid) continue;
        if (!formula) {
          formula = "=SUM(";
        } else {
          formula = formula + ")+SUM(";
        }
        formula = formula + tot.getExcelRange();
        outputTotals = formula.length > 5;
      }

      if (outputTotals) {
        formula = formula + ")";
        const cell = sh.getCell(this.CurLine, this.CurCol + 4);
        cell.formulas = [[formula]];
        cell.format.font.bold = true;
        this.OutputUnderline(sh);
      }

      if (onProcessTotals) await onProcessTotals(tots);

      this.CurLine++;
      this.Totals.delete(key);
    }
  }

  protected OutputUnderline(sh: Excel.Worksheet): void {
    for (let i = 0; i < 5; i++) {
      const borders = sh.getCell(this.CurLine, this.CurCol + i).format.borders.getItem("EdgeBottom");
      borders.style = "Continuous";
      borders.weight = "Thin";
      borders.color = "Black";
    }
  }

  async OutputHeader(sh: Excel.Worksheet, item: PublicCOATreeListDTO) {
    let cell = sh.getCell(this.CurLine, this.CurCol);
    cell.values = [[`'${item.AccountNumber}`]];
    cell.format.font.bold = true;

    cell = sh.getCell(this.CurLine, this.CurCol + 1);
    cell.values = [[item.CurrencyCode]];
    cell.format.font.bold = true;

    cell = sh.getCell(this.CurLine, this.CurCol + 2);
    cell.values = [[item.Description]];
    cell.format.font.bold = true;
    this.OutputUnderline(sh);

    this.CurLine++;
  }

  async OutputLine(sh: Excel.Worksheet, item: PublicCOATreeListDTO): Promise<void> {
    let cell = sh.getCell(this.CurLine, this.CurCol);
    cell.values = [[`'${item.AccountNumber}`]];
    cell.format.font.bold = false;
    cell = sh.getCell(this.CurLine, this.CurCol + 1);
    cell.values = [[item.CurrencyCode]];
    cell.format.font.bold = false;
    cell = sh.getCell(this.CurLine, this.CurCol + 2);
    cell.values = [[item.Description]];
    cell.format.font.bold = false;

    const formula = new GLRangeFormula(new ApiService());
    const wrapper = new ChoiceFormulaWrapper();
    const accountCell = this.storeOrGetFromCache(sh, this.CurLine, this.CurCol, "address");
    const currencyCell = this.storeOrGetFromCache(sh, this.CurLine, this.CurCol + 1, "address");
    await this.refreshTotals(sh);
    if (!this.simulationMode) {
      formula.formulaData = {
        companyId: this.FyCode,
        fromAccount: Helper.decorateWithExcelConcat(accountCell.address),
        toAccount: Helper.decorateWithExcelConcat(accountCell.address),
        currency: Helper.decorateWithExcelConcat(currencyCell.address),
        fromDate: Helper.decorateWithExcelConcat(this.DateFromCellRange),
        toDate: Helper.decorateWithExcelConcat(this.DateToCellRange),
        withOpeningBalance: "true",
        withMvts: "true",
        originalCurrency: "false",
        withZeroAccounts: "true",
        debits: "true",
        credits: "true",
      };
      const amtCell = sh.getCell(this.CurLine, this.CurCol + 4);
      amtCell.formulas = [[wrapper.wrap(this.formulasMode, 0, formula.generate())]];
      amtCell.format.font.bold = false;

      if (this.WithOrigCcy && item.CurrencyCode != this.RefCurrencyCode) {
        const formulaOrigCcy = new GLRangeFormula(new ApiService());
        formulaOrigCcy.formulaData = {
          companyId: this.FyCode,
          fromAccount: Helper.decorateWithExcelConcat(accountCell.address),
          toAccount: Helper.decorateWithExcelConcat(accountCell.address),
          currency: Helper.decorateWithExcelConcat(currencyCell.address),
          fromDate: Helper.decorateWithExcelConcat(this.DateFromCellRange),
          toDate: Helper.decorateWithExcelConcat(this.DateToCellRange),
          withOpeningBalance: "true",
          withMvts: "true",
          originalCurrency: "true",
          withZeroAccounts: "true",
          debits: "true",
          credits: "true",
        };
        sh.getCell(this.CurLine, this.CurCol + 3).formulas = [
          [wrapper.wrap(this.formulasMode, 0, formulaOrigCcy.generate())],
        ];
      }
    }

    this.CurLine++;
  }

  async generate(  list: Array<PublicTrialBalanceTreeListDTO>,
                   fyCode: string,
                   fyDesc: string,
                   dateFrom: Date,
                   dateTo: Date,
                   withOrigCurrency: boolean,
                   refCurrency: string,
                   excelContext: Excel.RequestContext) {
    this.cellCache = new Map<string, Excel.Range>();
    this.simulationMode = true;
    await this.doGenerate(list, fyCode, fyDesc, dateFrom, dateTo, withOrigCurrency, refCurrency, excelContext);
    this.simulationMode = false;
    await this._context.sync();
    await this.doGenerate(list, fyCode, fyDesc, dateFrom, dateTo, withOrigCurrency, refCurrency, excelContext);
    this.cellCache = null;
  }

  private async doGenerate(
    list: Array<PublicTrialBalanceTreeListDTO>,
    fyCode: string,
    fyDesc: string,
    dateFrom: Date,
    dateTo: Date,
    withOrigCurrency: boolean,
    refCurrency: string,
    excelContext: Excel.RequestContext
  ): Promise<void> {

    this.lastError = BalanceSheetPLError.noError;
    this.FyCode = fyCode;
    this.DateFrom = dateFrom;
    this.DateTo = dateTo;
    this.WithOrigCcy = withOrigCurrency;
    this.RefCurrencyCode = refCurrency;
    this._context = excelContext;

    const curCell = this._context.workbook.getActiveCell();
    curCell.load("rowIndex");
    curCell.load("columnIndex");
    await this._context.sync();

    this.CurLine = curCell.rowIndex;
    this.CurCol = curCell.columnIndex;

    const sh = this._context.workbook.worksheets.getActiveWorksheet();

    const fyDescCell = this.storeOrGetFromCache(sh, this.CurLine, this.CurCol, "address");
    const dateFromCell = this.storeOrGetFromCache(sh, this.CurLine, this.CurCol + 1, "address");
    const dateToCell = this.storeOrGetFromCache(sh, this.CurLine, this.CurCol + 2, "address");

    fyDescCell.values = [[fyDesc]];

    dateFromCell.values = [[Math.ceil(moment(this.DateFrom).toOADate())]];
    dateFromCell.numberFormat = [["dd/mm/yyyy"]];
    if (!this.simulationMode) this.DateFromCellRange = dateFromCell.address;

    dateToCell.values = [[Math.ceil(moment(this.DateTo).toOADate())]];
    dateToCell.numberFormat = [["dd/mm/yyyy"]];
    if (!this.simulationMode) this.DateToCellRange = dateToCell.address;
    this.CurLine += 2;

    const rootElem: PublicTrialBalanceTreeListDTO = {
      Children: list as PublicCOATreeListDTO[],
    };

    const stack = new Stack<PublicCOATreeListDTO>();
    stack.push(rootElem as PublicCOATreeListDTO);

    let prevItem: PublicCOATreeListDTO = null;

    while (stack.size() > 0) {
      const cur = stack.pop();

      if (cur.AccountNumber && cur.AccountNumber !== "zzzzzzzzzzzzzzz") {
        if (
          prevItem &&
          prevItem.AccountGroupingType === AccountGroupingType.Account &&
          cur.AccountGroupingType !== AccountGroupingType.Account
        ) {
          this.OutputUnderline(sh);
          await this.OutputTotals(sh, cur, this.onProcessTotalCellRanges.bind(this));
          this.CurLine++; // separate next section from previous one
        }

        if (cur.Children.length === 0 && cur.AccountGroupingType === AccountGroupingType.Account) {
          await this.OutputLine(sh, cur);
        } else {
          await this.OutputHeader(sh, cur);
          this.StoreTotal(cur.AccountNumber, cur.AccountNature, cur.AccountGroupingType);
        }
      }

      for (let i = cur.Children.length - 1; i >= 0; i--) {
        const child = cur.Children[i];
        stack.push(child);
      }
      prevItem = cur;
    }

    this.OutputUnderline(sh);
    await this.OutputTotals(sh, null, this.onProcessTotalCellRanges.bind(this));
  }
}
