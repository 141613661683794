export enum MainActionTypes {
  UPDATE_DISPLAY_FORMULAS = "UPDATE_DISPLAY_FORMULAS",

  COMPUTE_FORMULAS = "COMPUTE_FORMULAS",

  LOAD_COMPANY_LIST = "LOAD_COMPANY_LIST",
  SET_COMPANY_LIST = "SET_COMPANY_LIST",

  LOAD_ACCOUNT_LIST = "LOAD_ACCOUNT_LIST",
  SET_ACCOUNT_LIST = "SET_ACCOUNT_LIST",

  LOAD_CURRENCY_LIST = "LOAD_CURRENCY_LIST",
  SET_CURRENCY_LIST = "SET_CURRENCY_LIST"
}
