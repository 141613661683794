import {ApiKeyRequestResult} from "./apiKeyRequestDto";

export interface ApiKeyRequestStatusDTO
{
  RequestResult: ApiKeyRequestResult;
  ApiKey: string;
  TenantUserName: string;
  TenantName: string;
  TenantUserEmail: string;
}
