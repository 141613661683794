export interface PublicFiscalYearDTO
{
  ID: string;
  FiscalYearCode: string;
  CompanyName: string;
  FiscalYearDescription: string;

  StartDate: Date;
  EndDate: Date;

  CurrencyCode: string;

  ClassLength: number;
  GroupLength: number;
  MinAccountCodeLength: number;
  MaxAccountCodeLength: number;
}
