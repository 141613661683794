import IFormulaBase from "./formulaBase";
import {ApiService} from "@taskpane/core/services";
import GLRangeFormula from "./glRangeFormula";
import {GLBalanceFormula} from "./glBalanceFormula";

export default class FormulaFactory {
  getFormulaInstance(formula: string): IFormulaBase {
    const glRangeFormula = new GLRangeFormula(new ApiService());
    const glBalanceFormula = new GLBalanceFormula(new ApiService());
    return glRangeFormula.parse(formula) || glBalanceFormula.parse(formula);
  }
}
