import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import {defaultDatePickerStrings} from "@fluentui/react";

const resources = {
  en: {
    translation: {
      back: "Retour",
      close: "Fermer",

      connect: "Login",
      logout: "Logout",
      stop: "Stop",
      compute: "Compute",

      welcome: "Welcome to Peppermint Izi-Report addin !",
      authCta: "To start using it, please click on the button below to authenticate yourself.",

      displayFormulas: "Display formulas",

      company: "Company",
      account: "Account",
      accountFrom: "From account",
      accountTo: "To account",
      currency: "Currency",
      date: "Date",
      cell: "Cell",
      fromDate: "From date",
      toDate: "To date",
      openingBalance: "Opening balance",
      movements: "Movements",
      originalCurrency: "Original currency",
      pleaseChooseValue: "Please choose a value",
      pleaseEnterValue: "Please enter a value",
      dateFromCannotBeAfterDateTo: "From date cannot be greater than To date",
      withZeroAccounts: "With zero accounts",
      includeProvisionalEntries: "Include provisional entries",

      validate: "Validate",

      glBalanceFormula: "GL formula (one account)",
      glBalanceFormulaDesc: "Calculate an account balance",
      glRangeFormula: "GL formula (account range)",
      glRangeFormulaDesc: "Calculate a range of account's balance",

      balanceSheet: "Balance sheet",
      balanceSheetDesc: "Insert a balance sheet template",
      profitAndLoss: "Profit and loss",
      profitAndLossDesc: "Insert a profit and loss template",

      templatesPageHint: "Templates",
      formulasPageHint: "Formulas",
      workToolsPageHint: "Construction tools",
      mvtsPageHint: "Display movements",

      noFormula: "No formula selected",

      cancel: "Cancel",
      confirmationTitle: "Confirm",
      confirmationText: "Do you confirm ?",

      cellCoordinatesForDate: "Enter an Excel cell coordinates/variable name if needed",

      connectedAs: "You are connected as {{tenantUser}} {{tenantUserEmail}} on the tenant {{tenant}}",

      profit: "Profit",
      loss: "Loss",

      computePage: "Compute formulas",
      formulas: "Formulas",
      templates: "Templates",
      workToolsPage: "Construction tools",

      debits: "Debits",
      credits: "Credits",

      months: defaultDatePickerStrings.months.join(','),
      shortMonths: defaultDatePickerStrings.shortMonths.join(','),
      days: defaultDatePickerStrings.days.join(','),
      shortDays: defaultDatePickerStrings.shortDays.join(','),
      goToToday: defaultDatePickerStrings.goToToday,
      goToPreviousMonth: defaultDatePickerStrings.prevMonthAriaLabel,
      goToNextMonth: defaultDatePickerStrings.nextMonthAriaLabel,
      goToPreviousYear: defaultDatePickerStrings.prevYearAriaLabel,
      goToNextYear: defaultDatePickerStrings.nextYearAriaLabel,

      displayMvts: {
        entryDate: 'Date',
        number: 'No.',
        entryLineNumber: 'Line No.',
        voucher: 'Voucher',
        accountNumber: 'Account',
        label: 'Label',
        bookKeepingCurrency: 'Book keeping ccy',
        originalCurrency: 'Original currency',
      },

      grid : {
        noDataToDisplay: 'No records to display.'
      },

      displayPdf: {
        nextPage: 'Page suivante',
        prevPage: 'Page précédente',
        download: 'Télécharger'
      }
    },
  },
  fr: {
    translation: {
      back: "Retour",

      close: "Close",

      connect: "Se connecter",
      logout: "Se déconnecter",
      stop: "Arrêter",
      compute: "Calculer",

      welcome: "Bienvenue sur le complément Peppermint Izi-Report !",
      authCta: "Pour commencer à l'utiliser, veuillez cliquer sur le bouton ci-dessous afin de vous authentifier.",

      displayFormulas: "Afficher les formules",

      company: "Société",
      account: "Account",
      accountFrom: "Compte du...",
      accountTo: "Au",
      currency: "Monnaie",
      date: "Date",
      cell: "Cellule",
      fromDate: "Date du...",
      toDate: "Au",
      movements: "Mouvements",
      openingBalance: "Balance d'entrée",
      originalCurrency: "Monnaie originale",
      pleaseChooseValue: "Veuillez choisir une valeur",
      pleaseEnterValue: "Veuillez saisir une valeur",
      dateFromCannotBeAfterDateTo: "La date De ne peut pas être supérieure à la date A",
      withZeroAccounts: "Avec les comptes à zéro",
      includeProvisionalEntries: "Inclure les écritures provisoires",

      validate: "Valider",

      glBalanceFormula: "Formule GL (un compte)",
      glBalanceFormulaDesc: "Calculer le solde d'un compte",
      glRangeFormula: "Formule GL (intervalle de comptes)",
      glRangeFormulaDesc: "Calculer le solde d'une intervalle de comptes",

      balanceSheet: "Bilan",
      balanceSheetDesc: "Insérer un modèle de bilan",
      profitAndLoss: "Pertes et profits",
      profitAndLossDesc: "Insérer un modèle de pertes et profits",

      templatesPageHint: "Modèles",
      formulasPageHint: "Formules",
      workToolsPageHint: "Outils de construction",
      mvtsPageHint: "Afficher les mouvements",

      noFormula: "Aucune formule sélectionnée",

      cancel: "Annuler",
      confirmationTitle: "Confirmation",
      confirmationText: "Etes-vous sûr ?",

      cellCoordinatesForDate: "Saisie de coordonnées de cellule/nom de variable Excel si nécessaire",

      connectedAs: "Vous êtes connecté en tant que {{tenantUser}} {{tenantUserEmail}} sur le tenant {{tenant}}",

      profit: "Bénéfice",
      loss: "Pertes",

      computePage: "Calcul",
      formulas: "Formules",
      templates: "Modèles",
      workToolsPage: "Outils de construction",

      debits: "Débits",
      credits: "Crédits",

      months: "Janvier, Février, Mars, Avril, Mai, Juin, Juillet, Août, Septembre, Octobre, Novembre, Décembre",
      shortMonths: "Jan, Fév, Mar, Avr, Mai, Jui, Jul, Aou, Sep, Nov, Dec",
      days: "Dimanche, Lundi, Mardi, Mercredi, Jeudi, Vendredi, Samedi",
      shortDays: "D, L, M, M, J, V, S",
      goToToday: "Aujourd'hui",
      goToPreviousMonth: "Mois précédent",
      goToNextMonth: "Mois suivant",
      goToPreviousYear: "Année précédente",
      goToNextYear: "Année suivante",

      displayMvts: {
        entryDate: 'Date',
        number: 'No.',
        entryLineNumber: 'No. ligne',
        voucher: 'Pièce',
        accountNumber: 'Compte',
        label: 'Texte',
        bookKeepingCurrency: 'Monnaie réf.',
        originalCurrency: 'Monnaie orig.',
      },

      grid : {
        noDataToDisplay: 'Aucun enregistrement à afficher.'
      },

      displayPdf: {
        nextPage: 'Next page',
        prevPage: 'Previous page',
        download: 'Download'
      }
    },
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      // order and from where user language should be detected
      order: ["navigator", "htmlTag", "path", "subdomain"],

      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      cookieMinutes: 10,
      cookieDomain: "myDomain",

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: "/", sameSite: "strict" },
    },
    resources,
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .then(() => {});

export default i18n;
