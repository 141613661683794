import { Icon, ProgressIndicator, Toggle } from "@fluentui/react";
import { loadAccountList, loadCompanyList, loadCurrencyList } from "@taskpane/core/actionCreators";
import { ApiService } from "@taskpane/core/services";
import { useAppDispatch, useAppSelector } from "@taskpane/core/store";
import { setColor } from "@taskpane/theme";
import { AccountNature } from "@taskpane/types";
import { onFormatDate, onParseDateFromString} from "@taskpane/utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ProfitLoss from "../../../features/balanceSheetPL/profitLoss";
import {Button, PMComboBox} from "../../atoms";
import {PMDatePicker} from "../../atoms/datePIcker";

interface PLWizardProps {
  onBackClick: () => void;
}

export const PLWizard = (props: PLWizardProps) => {
  function handleBackOnClick() {
    props.onBackClick();
  }

  const { t, i18n } = useTranslation();

  const [selCompany, setSelCompany] = useState("");
  const [fromAccount, setFromAccount] = useState("");
  const [toAccount, setToAccount] = useState("");
  const [currency, setCurrency] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [originalCurrency, setOriginalCurrency] = useState(false);
  const [withZeroAccounts, setWithZeroAccounts] = useState(true);
  const [isValidating, setIsValidating] = useState(false);

  // error management
  const [selCompanyError, setSelCompanyError] = useState(null);
  const [fromAccountError, setFromAccountError] = useState(null);
  const [toAccountError, setToAccountError] = useState(null);
  const [currencyError, setCurrencyError] = useState(null);
  const [fromDateError, setFromDateError] = useState(null);
  const [toDateError, setToDateError] = useState(null);

  const dispatch = useAppDispatch();
  const { companyList, accountList, currencyList } = useAppSelector((state) => state.main);
  const [companyListOptions, setCompanyListOptions] = useState([]);
  const [accountListOptions, setAccountListOptions] = useState([]);
  const [currencyListOptions, setCurrencyListOptions] = useState([]);

  const validate = () => {
    let result = true;
    if (!selCompany.trim()) {
      setSelCompanyError(t("pleaseChooseValue"));
      result = false;
    }

    if (dateFrom > dateTo) {
      setFromDateError(t("dateFromCannotBeAfterDateTo"));
      result = false;
    }

    return result;
  };

  const onCompanyChange = (event, option, index, value) => {
    setSelCompany(option.key);
    const company = companyList.find((c) => c.ID === option.key);
    if (company) {
      setDateFrom(moment(company.StartDate).toDate());
      setDateTo(moment(company.EndDate).toDate());

      dispatch(loadAccountList(company.ID));
      dispatch(loadCurrencyList(company.ID));
    }
  };

  useEffect(() => {
    if (accountList && accountList.length > 0) {
      setAccountListOptions(
        accountList
          .filter((e) => e.AccountNature === AccountNature.Cost || e.AccountNature === AccountNature.Income)
          .map((e) => ({ key: e.AccountNumber, text: e.DisplayFormatedString }))
      );
    }
  }, [accountList]);

  useEffect(() => {
    if (currencyList && currencyList.length > 0) {
      setCurrencyListOptions(currencyList.map((e) => ({ key: e.Code, text: e.Code })));
    }
  }, [currencyList]);

  useEffect(() => {
    dispatch(loadCompanyList(true));
  }, [dispatch]);

  useEffect(() => {
    if (companyList && companyList.length > 0)
      setCompanyListOptions(companyList.map((element) => ({ key: element.ID, text: element.FiscalYearDescription })));
  }, [companyList]);

  function onSelectFromDate(date: Date | null | undefined) {
    setDateFrom(date);
  }

  function onSelectToDate(date: Date | null | undefined) {
    setDateTo(date);
  }

  function onWithZeroAccountsChange(event, checked) {
    setWithZeroAccounts(checked);
  }

  function onOriginalCurrencyChange(event, checked) {
    setOriginalCurrency(checked);
  }

  function onAccountFromChange(event, option, index, value) {
    setFromAccount(option.key);
  }

  function onCurrencyChange(event, option, index, value) {
    setCurrency(option.key);
  }

  function onAccountToChange(event, option, index, value) {
    setToAccount(option.key);
  }

  async function onOkClick() {
    if (!validate()) return;
    try {
      setIsValidating(true);

      await Excel.run(async (context) => {
        try {
          const calc = new ProfitLoss();
          const svc = new ApiService();

          const company = companyList.find((c) => c.ID === selCompany);
          if (company) {
            let actualFromAccount = fromAccount;
            let actualToAccount = toAccount;

            if (accountList && accountList.length > 0) {
              if (!actualFromAccount && accountList && accountList.length > 0) {
                actualFromAccount = accountList[0].AccountNumber;
              }
              if (!actualToAccount && accountList){
                actualToAccount = accountList[accountList.length - 1].AccountNumber;
              }
            }

            const list = await svc.getProfitAndLossTree(
              company.ID,
              moment(dateFrom).format("MM-DD-YYYY"),
              moment(dateTo).format("MM-DD-YYYY"),
              actualFromAccount,
              actualToAccount,
              currency,
              withZeroAccounts
            );
            if (list.ok) {
              await calc.generate(
                list.parsedBody,
                selCompany,
                company.FiscalYearDescription,
                dateFrom,
                dateTo,
                originalCurrency,
                company.CurrencyCode,
                context
              );
            } else {
              console.error("PLWizard : failed to get list of balance sheet data");
            }
          }
        } catch (e) {
          console.error("PLWizard:onOkClick exception occurred", e);
        }
      });
    } finally {
      setIsValidating(false);
    }
  }

  return (
    <div className="pt-4 col col-12">
      <div className="h-100 document">
        <div onClick={() => handleBackOnClick()} style={{ cursor: "pointer" }} className="d-flex">
          <Icon iconName="Back" className="ms-font-l" style={{ color: setColor("blue") }} />
          <span className="ms-font-l ms-2" style={{ color: setColor("blue") }}>
            {t("back")}
          </span>
        </div>

        <div className="mt-3 mb-2 ms-fontWeight-bold">
          <span>{t("profitAndLoss")}</span>
        </div>

        <div className="mb-2">
          <PMComboBox
            label={t("company")}
            defaultSelectedKey={selCompany}
            options={companyListOptions}
            onChange={onCompanyChange}
          ></PMComboBox>
          {selCompanyError ? <span style={{ color: setColor("red") }}>{selCompanyError}</span> : ""}
        </div>

        <div className="mb-2">
          <PMDatePicker
            label={t("fromDate")}
            allowTextInput
            onSelectDate={onSelectFromDate}
            formatDate={onFormatDate}
            parseDateFromString={onParseDateFromString(dateTo)}
            value={dateFrom}
          />
          {fromDateError ? <span style={{ color: setColor("red") }}>{fromDateError}</span> : ""}
        </div>
        <div className="mb-2">
          <PMDatePicker
            label={t("toDate")}
            allowTextInput
            onSelectDate={onSelectToDate}
            formatDate={onFormatDate}
            parseDateFromString={onParseDateFromString(dateTo)}
            value={dateTo}
            />
          {toDateError ? <span style={{ color: setColor("red") }}>{toDateError}</span> : ""}
        </div>

        <div className="mb-2">
          <PMComboBox
            label={t("accountFrom")}
            defaultSelectedKey={fromAccount}
            onChange={onAccountFromChange}
            options={accountListOptions}
          ></PMComboBox>
          {fromAccountError ? <span style={{ color: setColor("red") }}>{fromAccountError}</span> : ""}
        </div>

        <div className="mb-2">
          <PMComboBox
            label={t("accountTo")}
            defaultSelectedKey={toAccount}
            onChange={onAccountToChange}
            options={accountListOptions}
          ></PMComboBox>
          {toAccountError ? <span style={{ color: setColor("red") }}>{toAccountError}</span> : ""}
        </div>

        <div className="mb-2">
          <PMComboBox
            label={t("currency")}
            defaultSelectedKey={currency}
            onChange={onCurrencyChange}
            options={currencyListOptions}
          ></PMComboBox>
          {currencyError ? <span style={{ color: setColor("red") }}>{currencyError}</span> : ""}
        </div>

        <div className="mb-2">
          <Toggle label={t("originalCurrency")} checked={originalCurrency} onChange={onOriginalCurrencyChange}></Toggle>
        </div>

        <div className="mb-2">
          <Toggle label={t("withZeroAccounts")} checked={withZeroAccounts} onChange={onWithZeroAccountsChange}></Toggle>
        </div>

        <div className="mb-2">
          <Button label={t("validate")} onClick={onOkClick} />
        </div>
        {isValidating ? <ProgressIndicator></ProgressIndicator> : ""}
      </div>
    </div>
  );
};
