import {PublicCOATreeListDTO} from "./publicCoaTreeListDto";

export interface PublicTrialBalanceTreeListDTO extends Partial<PublicCOATreeListDTO>
{
   OpeningBalance? : number;
   OpeningBalanceInBookKeepingCurrency? : number;
   Movement? : number;
   MovementInBookKeepingCurrency? : number;
   Balance? : number;
   BalanceInBookKeepingCurrency? : number;
   BookKeepingCurrencyDecimalPositions? : number;
   OriginalCurrencyDecimalPositions? : number;
   Percentage?: number;
}
