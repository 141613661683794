import { AuthActions } from "@taskpane/core/actions";
import { AuthActionTypes } from "@taskpane/core/actionTypes";
import { AuthStateInfos } from "@taskpane/types";


const initialAuthState: AuthStateInfos = {
  apiKeyRequestId: null,
  authUrl: null,
  apiKey: null,
  tenant: null,
  tenantUser: null,
  tenantUserEmail: null,
  status: 'INIT'
};

export const getAuthSelector = state => state.auth;

export function authReducer(state: AuthStateInfos = initialAuthState, action: AuthActions): AuthStateInfos {
  switch (action.type) {
    case AuthActionTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        status: 'LOADING',
      };
    case AuthActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        status: 'SIGN_IN_SUCCESS',
        authUrl: action.payload.auth.authUrl,
        apiKeyRequestId: action.payload.auth.apiKeyRequestId
      };
    case AuthActionTypes.SIGN_IN_FAILURE:
      return {
        ...initialAuthState,
        status: 'SIGN_IN_FAILURE',
      };
    case AuthActionTypes.SIGN_OUT:
      return initialAuthState;
    case AuthActionTypes.SIGN_IN:
      return initialAuthState;
    case AuthActionTypes.GET_API_KEY:
      return { ... state,
        status: 'GET_API_KEY',};
    case AuthActionTypes.GET_API_KEY_SUCCESS:
      return {
        ...state,
        status: 'GET_API_KEY_SUCCESS',
        apiKey: action.payload.auth.apiKey,
        tenant: action.payload.auth.tenant,
        tenantUser: action.payload.auth.tenantUser,
        tenantUserEmail: action.payload.auth.tenantUserEmail
      }
    default:
      return state;
  }
}
