
export class Helper {
  public static decorateWithExcelConcat(cellCoordinates: string) {
    return `" & ${cellCoordinates} & "`;
  }

  public static removeExcelConcatDecoration(value: string) {
    const match = value.match(/("\s*&\s*)(\S*)(\s*&\s*")/i);
    if (match.length !== 4) return value;
    else return match[2];
  }

  public static hasExcelConcatDecoration(value: string): boolean {
    const match = value.match(/("\s*&\s*)(\S*)(\s*&\s*")/i);
    if (match && match.length === 4 &&
      match[1].indexOf('"') > -1 && match[1].indexOf('&') > -1 &&
      match[3].indexOf('"') > -1 && match[3].indexOf('&') > -1 ) return true;
    else return false;
  }

  public static async isCellRef(value: string, context: Excel.RequestContext): Promise<{isCellRef: boolean, value: string}>
  {
    //match $AA$52 or A2
    let res = { isCellRef: false, value: value };

    try
    {
      const split = value.split("!");

      if (split.length == 2) {

        let worksheet = null;
        worksheet = context.workbook.worksheets.getItem(split[0]);

        if (worksheet) {

          const cell = worksheet.getRange(split[1]);
          cell.load("values"); cell.load("formulas");
          await context.sync();
          const res1 = { isCellRef: (cell.values.length > 0 && cell.values[0].length > 0) ||
              (cell.formulas.length > 0 && cell.formulas[0].length > 0),
            value: cell.values[0][0] }
          return res1;
        }
      }
      else {

        const range = context.workbook.worksheets.getActiveWorksheet().getRange(value);
        range.load("values"); range.load("formulas");
        await context.sync();
        const res1 = { isCellRef: (range.values.length > 0 && range.values[0].length > 0) ||
            (range.formulas.length > 0 && range.formulas[0].length > 0),
          value: range.values[0][0] }
        return res1;
      }
    }
    catch(e)
    {
      console.error("an error occured inside isCellRef, ", e);
      return res;
    }
  }

  public static ensureAsString(celldata: string): string
  {
    return !isNaN(Number.parseFloat(celldata)) ? `'${celldata}` : celldata;
  }
}
