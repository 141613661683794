import { lazy } from "react";

const Login = lazy(() => import("@taskpane/components").then(({ Login }) => ({ default: Login })));
const Home = lazy(() => import("@taskpane/components").then(({ Home }) => ({ default: Home })));

export const routes = [
  {
    label: "login",
    path: "/login",
    exact: false,
    isPrivate: false,
    Component: Login,
  },
  {
    label: "home",
    path: "/",
    exact: true,
    isPrivate: true,
    Component: Home,
  },
];
