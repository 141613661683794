import {createContext} from "react";
import {IApiService} from "./interfaces/IAPI.service";
import {ICellMvtsLinkService} from "./interfaces/ICellMvtsLink.service";

export interface IServicesInstances {
  apiService?: IApiService;
  cellMvtsLink?: ICellMvtsLinkService;
}

export const ServicesContext = createContext<IServicesInstances | undefined>(undefined);
