import { store } from "@taskpane/core/store";

export type HttpResponse<T> = Response & {
  parsedBody?: T;
};

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export type QueryParams = {
  params: {
    [key: string]: string | number;
  };
};

const API_URL = process.env.API_URL;
const API_VERSION = process.env.API_VERSION;
const API_PORT = process.env.API_PORT;

export function encodeQueryString({ params }: QueryParams) {
  const keys = Object.keys(params);
  return keys.length
    ? "?" + keys.map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])).join("&")
    : "";
}

async function http<T>(request: RequestInfo, isBlob: boolean = false, TIMEOUT = 5000): Promise<HttpResponse<T>> {
  const controller = new AbortController();
  //const id = setTimeout(() => controller.abort(), TIMEOUT);
  try {
    const response: HttpResponse<T> = await fetch(request);
    if (isBlob) {
      // @ts-ignore
      response.parsedBody = await response
        .clone()
        .blob()
        .catch(() => response.text())
    } else {
      response.parsedBody = await response
        .clone()
        .json()
        .catch(() => response.text());
    }

    //clearTimeout(id);
    return response;
  } catch (error) {
    console.error("http routine : ", error);
    throw new Error(error);
  } finally {
    //controller.abort();
  }
}

function setHeaders(contentType: string = '') {
  const { apiKey } = store.getState().auth;
  const headers: RequestInit["headers"] = {
    "Content-Type": contentType ? contentType : "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "*",
  };
  if (apiKey) {
    headers["X-apikey"] = apiKey;
  }
  return headers;
}

async function get<R>(
  request: RequestInfo,
  queryParams: QueryParams = { params: {} },
  config?: RequestInit,
  TIMEOUT?: number,
  contentType?: string,
  isBlob?: boolean
): Promise<HttpResponse<R>> {
  const query = encodeQueryString(queryParams);
  const headers = setHeaders(contentType ? contentType : '');
  const init: typeof config = { method: HttpMethod.GET, headers, ...config };
  return await http<R>(new Request(request + query, init), isBlob, TIMEOUT);
}

async function post<B, R>(
  request: RequestInfo,
  body: B,
  config?: RequestInit,
  TIMEOUT?: number
): Promise<HttpResponse<R>> {
  const headers = setHeaders();
  const init: typeof config = {
    method: HttpMethod.POST,
    headers,
    body: JSON.stringify(body),
    ...config,
  };
  return await http<R>(new Request(request, init), false, TIMEOUT);
}
async function put<B, R>(request: RequestInfo, body: B, config?: RequestInit): Promise<HttpResponse<R>> {
  const headers = setHeaders();
  const init: typeof config = {
    method: HttpMethod.PUT,
    headers,
    body: JSON.stringify(body),
    ...config,
  };
  return await http<R>(new Request(request, init));
}

async function patch<B, R>(
  request: RequestInfo,
  body: Partial<B>,
  config?: RequestInit,
  TIMEOUT?: number
): Promise<HttpResponse<R>> {
  const headers = setHeaders();
  const init: typeof config = {
    method: HttpMethod.PATCH,
    headers,
    body: JSON.stringify(body),
    ...config,
  };
  return await http<R>(new Request(request, init), false, TIMEOUT);
}

async function remove<R>(request: RequestInfo, config?: RequestInit, TIMEOUT?: number): Promise<HttpResponse<R>> {
  const headers = setHeaders();
  const init: typeof config = { method: HttpMethod.DELETE, headers, ...config };
  return await http<R>(new Request(request, init), false, TIMEOUT);
}

export function setApiURL(path: string) {
  return `${API_URL}/${path}`;
}

export const request = {
  get,
  post,
  put,
  patch,
  remove,
};

export type Request = typeof request;
export type RequestMethod = keyof Request;
