import React, { CSSProperties, forwardRef, memo, ReactNode } from "react";

export interface CardProps {
  label?: string;
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

type Ref = HTMLDivElement;

export const Card = memo(
  forwardRef<Ref, CardProps>(({ label, children, className = "", onClick, style }) => (
    <div style={style} className={`card ${className}`} onClick={onClick}>
      {label && label}
      {children && children}
    </div>
  ))
);
