import { FormulasMode } from "@taskpane/utils";

import { CellScanner, EndProgressCallback, InitProgressCallback, ProgressCallback, StopCallback } from "./cellScanner";
import ChoiceFormulaWrapper from "./choiceFormulaWrapper";

export default class FormulasModeClass {
  async changeFormulasMode(
    mode: FormulasMode,
    initProgress: InitProgressCallback,
    progress: ProgressCallback,
    endProgress: EndProgressCallback,
    cancellationToken: any,
    context: Excel.RequestContext
  ) {
    const cellScanner = new CellScanner();
    const choiceFormulaWrapper = new ChoiceFormulaWrapper();
    await cellScanner.scan(
      (v, f, loadingContext, context): string => {
        let newWrap = f;
        if (f && v !== undefined && v !== null) {
          const res = choiceFormulaWrapper.unwrap(f);
          if (res) {
            if (res.formula && res.value !== undefined && res.value !== null)
              newWrap = choiceFormulaWrapper.wrap(mode, res.value, res.formula);
          }
        }
        return newWrap;
      },
      initProgress,
      progress,
      endProgress,
      context,
      null,
      cancellationToken

    );
  }
}
