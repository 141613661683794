import {
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  IPivotItemProps,
  on,
  Pivot,
  PivotItem, Spinner, SpinnerSize,
  TooltipHost,
} from "@fluentui/react";
import {DefaultButton, PrimaryButton} from "@fluentui/react/lib/Button";
import {useBoolean, useId} from "@fluentui/react-hooks";
import {
  Button,
  ComputePage, DisplayMvts,
  Footer,
  FormulasList,
  TemplatesMenu,
  TemplateView,
  WorkToolsPage
} from "@taskpane/components";
import {signOut} from "@taskpane/core/actionCreators";
import {useAppDispatch, useAppSelector} from "@taskpane/core/store";
import {dialogStyles, FormulasMode} from "@taskpane/utils";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import { PublicEntryLineDTO } from "src/taskpane/@types/publicEntryLineDto";
import ChoiceFormulaWrapper from "../../../features/choiceFormulaWrapper";
import FormulaFactory from "../../../features/formulaFactory";


let currentPivotItem = null;

export const Home = () => {
  const { t } = useTranslation();
  const { tenant, tenantUserEmail, tenantUser } = useAppSelector((state) => state.auth);
  const formulasIconTooltipId = useId("formulasIconTooltip");
  const templatesIconTooltipId = useId("templatesIconTooltipId");
  const workToolsIconTooltipId = useId("workToolsIconTooltipId");
  const mvtsIconTooltipId = useId("mvtsIconTooltipId");
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [hasFormula, setHasFormula] = useState<boolean>(false);
  const [mvtsData, setMvtsData] = useState<PublicEntryLineDTO[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const dialogContentProps = {
    type: DialogType.normal,
    title: t("confirmationTitle"),
    closeButtonAriaLabel: t("close"),
    subText: t("confirmationText"),
  };

  const labelId: string = useId("dialogLabel");
  const subTextId: string = useId("subTextLabel");

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: undefined,
    }),
    [labelId, subTextId]
  );

  function onFormulasIconRender(
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
  ): JSX.Element | null {
    if (!link || !defaultRenderer) {
      return null;
    }

    return (
      <TooltipHost content={t("formulasPageHint")} id={formulasIconTooltipId}>
        {defaultRenderer({ ...link, itemIcon: undefined })}
        <Icon iconName={link.itemIcon} />
      </TooltipHost>
    );
  }

  function onTemplatesIconRender(
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
  ): JSX.Element | null {
    if (!link || !defaultRenderer) {
      return null;
    }

    return (
      <TooltipHost content={t("templatesPageHint")} id={templatesIconTooltipId}>
        {defaultRenderer({ ...link, itemIcon: undefined })}
        <Icon iconName={link.itemIcon} />
      </TooltipHost>
    );
  }

  function onWorkToolsIconRender(
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
  ): JSX.Element | null {
    if (!link || !defaultRenderer) {
      return null;
    }

    return (
      <TooltipHost content={t("workToolsPageHint")} id={workToolsIconTooltipId}>
        {defaultRenderer({ ...link, itemIcon: undefined })}
        <Icon iconName={link.itemIcon} />
      </TooltipHost>
    );
  }
  async function onSelectionChangedEventHandler(event) {
    if (currentPivotItem.props.itemKey !== 'displayMvtsPivotItem') return;

    return await Excel.run(async (context) => {
      let activeCell = context.workbook.getActiveCell();
      activeCell.load("formulas, values");
      await context.sync();

      const activeFormula = activeCell.formulas.length > 0 && activeCell.formulas[0].length > 0 ? activeCell.formulas[0][0] : null;
      if (!activeFormula) {

        setHasFormula(false);
        setMvtsData([]);
        return;
      } else setHasFormula(true);

      setIsLoadingData(true);

      const wrapper = new ChoiceFormulaWrapper();
      const res = wrapper.unwrap(activeFormula);
      if (res && res.formula) {
        const originalMode = res.mode;
        let changedFormulaMode = false;
        if (originalMode !== FormulasMode.Formulas) {
          activeCell.formulas = [[ wrapper.wrap(FormulasMode.Formulas, res.value, res.formula)]];
          activeCell = context.workbook.getActiveCell();
          activeCell.load("formulas, values");
          await context.sync();
          changedFormulaMode = true;
        }

        const objectFormula = new FormulaFactory().getFormulaInstance(activeCell.values[0][0]);

        if (changedFormulaMode) {
          activeCell.formulas = [[ wrapper.wrap(originalMode, res.value, res.formula)]];
          await context.sync();
        }

        const result = await objectFormula.getMvts();
        setMvtsData(result.parsedBody);
        setIsLoadingData(false);
      }
    });
  }

  let onSelectionChangedEvent;
  useEffect(() => {
    console.debug('useEffect init');
    if (!Excel) return;
    Excel.run(async (context) => {
      onSelectionChangedEvent = await context.workbook.onSelectionChanged.add(onSelectionChangedEventHandler);
      console.debug('initialized onSelectionChangedEvent');
    })
  }, []);


  async function onSignout() {
    toggleHideDialog();
    await Excel.run(onSelectionChangedEvent.context, async (context) => {
      onSelectionChangedEvent.remove();
      onSelectionChangedEvent = null;
      console.debug('event handler for onSelectionChanged removed successfully');
    })
  }


  function onMvtsIconRender(
    link?: IPivotItemProps,
      defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null
  ): JSX.Element | null {
      if (!link || !defaultRenderer) {
        return null;
      }

      return (
        <TooltipHost content={t("mvtsPageHint")} id={mvtsIconTooltipId}>
          {defaultRenderer({ ...link, itemIcon: undefined })}
          <Icon iconName={link.itemIcon} />
        </TooltipHost>
      );
  }

  async function onPivotLinkClick(item? :PivotItem, ev?) {
    currentPivotItem = item;
    if (currentPivotItem.props.itemKey === 'displayMvtsPivotItem') {
      await onSelectionChangedEventHandler(null);
    }
  }

  return (
    <TemplateView footer={<Footer />}>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              toggleHideDialog();
              dispatch(signOut());
            }}
            text="OK"
          />
          <DefaultButton onClick={toggleHideDialog} text={t("cancel")} />
        </DialogFooter>
      </Dialog>
      <Pivot
        onLinkClick={onPivotLinkClick}
        className="mt-1" linkSize="large">
        <PivotItem style={{ margin: 0, padding: 0 }} itemIcon="Home">
          <div className="pt-4">
            <div className="col col-12 mb-4">
              <div className="ms-fontSize-su text-center">
                <Icon iconName="CubeShape" aria-hidden="true" />
              </div>
              <div className="h-25 mb-4 fw-bold text-center fs-4">{t("welcome")}</div>
              <div className="h-25 text-center m-3">{t("connectedAs", { tenantUser, tenantUserEmail, tenant })}</div>
            </div>
            <div className="col col-12 d-flex justify-content-center">
              <Button primary label={t("logout")} onClick={onSignout} />
            </div>
          </div>
        </PivotItem>
        <PivotItem style={{ margin: 0, padding: 0 }} itemIcon="NumberedList" onRenderItemLink={onFormulasIconRender}>
          <FormulasList></FormulasList>
        </PivotItem>
        <PivotItem style={{ margin: 0, padding: 0 }} itemIcon="Generate" onRenderItemLink={onTemplatesIconRender}>
          <TemplatesMenu></TemplatesMenu>
        </PivotItem>
        <PivotItem style={{ margin: 0, padding: 0 }} itemIcon="Refresh">
          <ComputePage></ComputePage>
        </PivotItem>
        <PivotItem style={{margin: 0, padding: 0}} itemIcon="ConstructionCone" onRenderItemLink={onWorkToolsIconRender}>
          <WorkToolsPage></WorkToolsPage>
        </PivotItem>
        <PivotItem itemKey="displayMvtsPivotItem" style={{margin: 0, padding: 0}} itemIcon="PageList" onRenderItemLink={onMvtsIconRender}>
          {isLoadingData && <Spinner size={SpinnerSize.large}></Spinner>}
          {!isLoadingData && <DisplayMvts data={mvtsData} hasFormula={hasFormula}></DisplayMvts>}
        </PivotItem>
      </Pivot>
    </TemplateView>
  );
};
