import {Grid, GridCellProps, GridColumn, GridSortChangeEvent} from "@progress/kendo-react-grid";
import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {GridCellPdfIcon} from "./gridCellPdfIcon";
import {Popup} from "@progress/kendo-react-popup";
import {Menu, MenuItemModel, MenuSelectEvent} from "@progress/kendo-react-layout";
import {DateCell} from "./dateCell";
import PdfViewer from "./displayPdf";
import {Window} from "@progress/kendo-react-dialogs";
import {useTranslation} from "react-i18next";
import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {useServices} from "src/taskpane/core/tools";
import {PublicEntryLineDTO} from "../../../@types/publicEntryLineDto";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const loadingPanel = (
  <div className="k-loading-mask">
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

const initialSort: Array<SortDescriptor> = [
  { field: "EntryNumber", dir: "asc" },
];

interface DisplayMvtsProps {
  data: PublicEntryLineDTO[];
  hasFormula: boolean;
}

export const DisplayMvts: React.FunctionComponent<DisplayMvtsProps> = ({ data, hasFormula}: DisplayMvtsProps) => {
  const query = useQuery();
  const { t, i18n } = useTranslation();
  const { apiService } = useServices();

  const [offset, setOffset] = useState<any>();
  const [popupOpen, setPopupOpen] = useState<boolean>();
  const [pdfItems, setPdfItems] = useState<MenuItemModel[]>([]);
  const [dataItem, setDataItem] = useState<PublicEntryLineDTO>();
  const [dataItemIndex, setDataItemIndex] = useState<number>();
  const [pdfDialogOpen, setpdfDialogOpen] = useState<boolean>(false);
  const [pdfId, setpdfId] = useState<string>("");
  const [sort, setSort] = React.useState(initialSort);
  const [bookKeepingCurrencyCode, setBookkeepingCurrencyCode] = useState<string>(t('displayMvts.bookKeepingCurrency'));

  const popupContentRef = useRef(null);

  const blurTimeoutRef = useRef(null);

  useEffect(() => {
    if (data != undefined && data.length > 0) {
      setBookkeepingCurrencyCode(data[0].BookKeepingCurrencyCode);
    }
  }, [data]);

  function onRowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      onContextMenu: (e) => {
        e.preventDefault();
        handleContextMenuOpen(e, dataItem.dataItem);
      },
    };
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  }

  function handleContextMenuOpen(e, dataItem) {
    setDataItem(dataItem);
    setDataItemIndex(data.findIndex((p) => p.uid === dataItem.uid));
    setOffset({ left: e.clientX, top: e.clientY });
    setPopupOpen(true);
  }

  function onPopupBlurHandler() {
    clearTimeout(blurTimeoutRef.current);

    blurTimeoutRef.current = setTimeout(onBlurTimeout, 200);
  }

  function onContainerClickHandler() {
    setPopupOpen(false);
  }

  function onPopupOpen(e) {
    setPdfItems(
      dataItem.StorageItems.map((item) => {
        return {
          text: item.FileName,
          data: item.ID,
        } as MenuItemModel;
      })
    );
    popupContentRef.current.focus();
  }

  function onPopupFocus() {
    // the user is still inside the content
    clearTimeout(blurTimeoutRef.current);
  }

  function onBlurTimeout() {
    // the user is now outside the popup
    setPopupOpen(false);
  }

  function onSelect(event: MenuSelectEvent) {
    setpdfId(event.item.data);
    setpdfDialogOpen(true);
  }

  function togglePdfDialog() {
    setpdfDialogOpen(!pdfDialogOpen);
  }

  function onSinglePdfClick(e, id, dataItem) {
    if (id == undefined) {
      handleContextMenuOpen(e, dataItem);
    } else {
      setpdfId(id);
      setpdfDialogOpen(true);
    }
  }

  const PdfCustomCell = (props: GridCellProps) => <GridCellPdfIcon {...props} onPdfClick={onSinglePdfClick} />;
  if (!hasFormula) {
    return (<span>{t('noFormula')}</span>)
  } else {
    return (
      <>
        <div className="container-fluid vh-100 pa-0" onClick={onContainerClickHandler}>
          {pdfDialogOpen && (
            <Window
              stage={'FULLSCREEN'}
              resizable={false}
              draggable={false}
              minimizeButton={() => <></>}
              restoreButton={() => <></>}
              modal={true}
              onClose={togglePdfDialog}
            >
              {pdfId && <PdfViewer pdfId={pdfId} onClose={() => setpdfDialogOpen(false)}/>}
            </Window>
          )}
          <Popup offset={offset} show={popupOpen} onOpen={onPopupOpen} popupClass={"popup-content"}>
            <div tabIndex={-1} ref={popupContentRef} onFocus={onPopupFocus} onBlur={onPopupBlurHandler}>
              <Menu style={{display: "inline-block"}} vertical={true} items={pdfItems} onSelect={onSelect}></Menu>
            </div>
          </Popup>
          {data.length === 0 && loadingPanel}
          <Grid rowRender={onRowRender}
                data={orderBy(data, sort)}
                style={{height: "88vh"}}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                  setSort(e.sort);
                }}>
            <GridColumn field="EntryDate" format={"{0:d}"} title={t('displayMvts.entryDate')} cell={DateCell}
                        width={100}/>
            <GridColumn field="EntryNumber" title={t('displayMvts.number')} width={50}/>
            <GridColumn field="EntryLineNumber" title={t('displayMvts.entryLineNumber')} width={50}/>
            <GridColumn field="Voucher" title={t('displayMvts.voucher')} width={100}/>
            <GridColumn field="AccountNumber" title={t('displayMvts.accountNumber')} width={100}/>
            <GridColumn field="CurrencyCode" title={t('currency')} width={60}/>
            <GridColumn field="Label" title={t('displayMvts.label')}/>
            <GridColumn field="BookKeepingCurrencyAmount" title={bookKeepingCurrencyCode} width={120} format="{0:n2}"/>
            <GridColumn field="OriginalAmount" title={t('displayMvts.originalCurrency')} width={120} format="{0:n2}"/>
            <GridColumn field="StorageItems" title=" " cell={PdfCustomCell} width={70}/>
          </Grid>
        </div>
      </>
    );
  }
};
