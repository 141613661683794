import {css} from "@emotion/css";

export const buttonStyle = css`
    border: 0px;
    padding-left: 0px;
    cursor: pointer;
    background: transparent;
    &:hover {
      border-radius: 50%;
      background-color: #E8E8E8;
    };
`;
