import React from 'react';
import {ComboBox, ICalloutProps, IComboBoxProps} from "@fluentui/react";

export const comboBoxCalloutProps : ICalloutProps =
  {
    calloutMaxHeight: 320,
  };

export const PMComboBox = (props: IComboBoxProps) => {
  return (<ComboBox
    allowFreeform={true}
    calloutProps={comboBoxCalloutProps}
    dropdownWidth={310} {...props}/>)
}
