import { ViewLoader } from "@taskpane/components";
import { PrivateRoute, routes } from "@taskpane/routes";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

export const Routes = () => (
  <Suspense fallback={<ViewLoader />}>
    <Switch>
      {routes.map(({ label, path, exact, isPrivate, Component }) =>
        isPrivate ? (
          <PrivateRoute key={label} path={path} exact={exact} component={Component} />
        ) : (
          <Route key={label} path={path} exact={exact}>
            <Component />
          </Route>
        )
      )}
    </Switch>
  </Suspense>
);
