import * as H from "history";
import React, {FC, PropsWithChildren} from "react";
import { Redirect, Route } from "react-router-dom";
import {useAppSelector} from "@taskpane/core/store";

export interface AppRouteProps {
  path: string;
  provider?: FC<PropsWithChildren>;
  component: React.ElementType<any>;
  location?: H.Location;
  exact?: boolean;
}

export const PrivateRoute = ({ component: Component, provider: Provider, ...props }: AppRouteProps) => {
  const { apiKey } = useAppSelector((state) => state.auth);
  const isAuthenticated = !!apiKey;

  if (!Provider) {
    return (
      <Route
        {...props}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }

  return (
    <Provider>
      <Route
        {...props}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    </Provider>
  );
};
