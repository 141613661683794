import React, {useState} from "react";
import {Card} from "../../organisms";
import {BalanceSheetWizard} from "../balancesheetwizard";
import {PLWizard} from "../plwizard";

import "./styles.css";
import {useTranslation} from "react-i18next";

enum TemplateType { ttList, ttBilan, ttPL};

export const TemplatesMenu: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [ currentTemplate, setCurrentTemplate ] = useState(TemplateType.ttList);

  const onClickBackButton = () => {
    setCurrentTemplate(TemplateType.ttList);
  }

  switch(currentTemplate) {
    case TemplateType.ttList:
      return (
        <div className="pt-4">
          <div className="mt-1 mb-2 ms-fontWeight-bold">
            <span>{t("templates")}</span>
          </div>
          <Card className="card-template mb-4" onClick={() => setCurrentTemplate(TemplateType.ttBilan)}>
            <div className="card-body">
              <h5 className="card-title">
                {t("balanceSheet")}
              </h5>
              <p className="card-text">
                {t("balanceSheetDesc")}
              </p>
            </div>
          </Card>
          <Card className="card-template mb-4" onClick={() => setCurrentTemplate(TemplateType.ttPL)}>
            <div className="card-body">
              <h5 className="card-title">
                {t("profitAndLoss")}
              </h5>
              <p className="card-text">
                {t("profitAndLossDesc")}
              </p>
            </div>
          </Card>
        </div>)
    case TemplateType.ttBilan:
      return (<>
        <BalanceSheetWizard onBackClick={() => onClickBackButton()}></BalanceSheetWizard>
      </>)
    case TemplateType.ttPL:
      return (<>
        <PLWizard onBackClick={() => onClickBackButton()}></PLWizard>
      </>)
  }

}
