import {AccountGroupingType, AccountNature} from "./publicAccountDto";

export interface PublicCOATreeListDTO {
  ID: string;
  AccountNumber: string;
  CurrencyCode: string;
  Description: string;
  FiscalYearID: string;
  GroupId: string;
  HasChildren: boolean;
  Nature: string;
  AccountType: string;
  Tax: string;
  Reevaluation: string;
  Children: Array<PublicCOATreeListDTO>;
  AccountGroupingType: AccountGroupingType;
  AccountNature: AccountNature;
  CashflowCategory: string;
}
