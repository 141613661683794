import {FC, PropsWithChildren} from "react";
import {IServicesInstances, ServicesContext} from "../core/services/servicesContext";
import {ApiService} from "@taskpane/core/services";
import React from "react";
import {CellMvtsLinkService} from "../core/services/cellMvtsLink.service";

export const Services: FC<PropsWithChildren> = ({ children }: any) => {
  const services: IServicesInstances = {
    apiService: new ApiService(),
    cellMvtsLink: new CellMvtsLinkService(),
  };

  return (
    <>
      <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>
    </>
  );
};
