import { FormulasMode } from "@taskpane/utils";

import {
  CellScanner,
  EndProgressCallback,
  InitProgressCallback,
  LoadContextStatus,
  ProgressCallback,
  StopCallback,
} from "./cellScanner";
import ChoiceFormulaWrapper from "./choiceFormulaWrapper";
import IFormulaBase from "./formulaBase";
import FormulaFactory from "./formulaFactory";
import FormulasModeClass from "./formulasModeClass";

export default class ComputeFormulas {
  async compute(
    mode: FormulasMode,
    initProgress: InitProgressCallback,
    progress: ProgressCallback,
    endProgress: EndProgressCallback,
    cancellationToken: any,
    context: Excel.RequestContext
  ): Promise<void> {
    const scanner = new CellScanner();
    scanner.concurrencyLimit = parseInt(process.env.COMPUTATION_MAX_CONCURRENT, 10);
    const choiceFormulaWrapper = new ChoiceFormulaWrapper();
    const formulaFactory = new FormulaFactory();

    async function processingCallback(value, formula, loadContextMode: LoadContextStatus, context): Promise<string> {
      try {
        if (formula && value !== undefined && value !== null) {
          const actualFormula: IFormulaBase = formulaFactory.getFormulaInstance(value);
          if (actualFormula) {
            // await actualFormula.resolveReferences(loadContextMode !== LoadContextStatus.write, context);
            const unwrappedFormula = choiceFormulaWrapper.unwrap(formula);
            formula = choiceFormulaWrapper.wrap(
              mode,
              (await actualFormula.getAmount()) as number,
              unwrappedFormula.formula
            );
          }
        }
        return formula;
      } catch (e) {
        console.error("ComputeFormulas.compute: callback, an error occurred ", e);
        return formula;
      }
    }

    // set formulas in formula display mode (so any argument as a cell is resolved by excel, eg. "#PMGLRange/" & B2 & "/1000/2000" ..... )
    await new FormulasModeClass().changeFormulasMode(
      FormulasMode.Formulas,
      initProgress,
      progress,
      null,
      cancellationToken,
      context
    );
    await context.sync();
    console.log("did change formulas mode 1");
    setTimeout(() => {}, 2000);
    // compute amounts
    await scanner.scan(
      processingCallback,
      initProgress,
      progress,
      endProgress,
      context,
      LoadContextStatus.loadFormulasValues,
      cancellationToken,
    );
    await context.sync();
    console.log("did computation");
    // set formulas back in the original display mode
    await new FormulasModeClass().changeFormulasMode(mode, initProgress, progress, null, cancellationToken, context);
    await context.sync();
    console.log("did change formulas mode 2");
  }
}
