import { Icon } from "@fluentui/react";
import { Button, Footer, TemplateView } from "@taskpane/components";
import {getApiKey, signIn} from "@taskpane/core/actionCreators";
import { useAppDispatch, useAppSelector } from "@taskpane/core/store";
import React, {useEffect, useState} from "react";
import { Redirect } from "react-router-dom";
import {useRedirect} from "@taskpane/hooks";
import {useTranslation} from "react-i18next";
import {setColor} from "@taskpane/theme";

export const Login = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { apiKey, authUrl, status } = useAppSelector((state) => state.auth);

  const [ loginError, setLoginError ] = useState(null);

  const { redirectTo } = useRedirect();

  const handleOnSubmit = async () => {

    dispatch(signIn(true));


  };

  useEffect(() => {
    if (status === "SIGN_IN_SUCCESS") {
      const dialogClosed = async (_: any): Promise<void> => {
        dispatch(getApiKey(true));
        if (apiKey) redirectTo("/");
      };

     Office.context.ui.displayDialogAsync(authUrl, null, result => {
        const dialog = result.value;
        dialog.addEventHandler(Office.EventType.DialogEventReceived, dialogClosed);
        console.log(result);
      });
    }
  }, [apiKey, authUrl, dispatch, redirectTo, status]);

  return (
    <>
      {!apiKey ? (
        <TemplateView footer={<Footer />}>
          <div className="col col-12 mb-4">
            <div className="ms-fontSize-su text-center">
              <Icon iconName="CubeShape" aria-hidden="true" />
            </div>
            <div className="mb-4 fw-bold text-center fs-4">{t("welcome")}</div>
            <div className="m-3 text-center">
              {t("authCta")}
            </div>
          </div>
          <div className="col col-12 d-flex justify-content-center">
            <Button primary label={t("connect")} onClick={() => handleOnSubmit()} />
            { loginError ? <span style={{ color: setColor("red") }}>{loginError}</span> : '' }
          </div>
        </TemplateView>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};
