import {
  ComputeFormulasAction,
  LoadAccountListAction,
  LoadCompanyListAction, LoadCurrencyList,
  SetAccountListAction,
  SetCompanyListAction, SetCurrencyListAction,
  UpdateDisplayFormulasAction
} from "@taskpane/core/actions";
import {MainActionTypes} from "@taskpane/core/actionTypes";
import {PublicAccountDTO, PublicCurrencyDTO, PublicFiscalYearDTO} from "@taskpane/types";

export function updateDisplayFormulas(payload: boolean): UpdateDisplayFormulasAction {
  return {
    type: MainActionTypes.UPDATE_DISPLAY_FORMULAS,
    payload: payload
  };
}

export function computeFormulas(): ComputeFormulasAction {
  return {
    type: MainActionTypes.COMPUTE_FORMULAS
  }
}

export function loadCompanyList(payload: boolean): LoadCompanyListAction {
  return {
    type: MainActionTypes.LOAD_COMPANY_LIST,
    payload: payload
  }
}

export function setCompanyList(payload: Array<PublicFiscalYearDTO>): SetCompanyListAction {
  return {
    type: MainActionTypes.SET_COMPANY_LIST,
    payload: payload
  }
}

export function loadAccountList(fiscalYearId: string): LoadAccountListAction {
  return {
    type: MainActionTypes.LOAD_ACCOUNT_LIST,
    payload: {fiscalYearId: fiscalYearId }
  }
}

export function setAccountList(payload: Array<PublicAccountDTO>): SetAccountListAction {
  return {
    type: MainActionTypes.SET_ACCOUNT_LIST,
    payload: payload
  }
}

// currencies
export function loadCurrencyList(fiscalYearId: string): LoadCurrencyList {
  return {
    type: MainActionTypes.LOAD_CURRENCY_LIST,
    payload: {fiscalYearId: fiscalYearId}
  }
}

export function setCurrencyList(payload: Array<PublicCurrencyDTO>): SetCurrencyListAction {
  return {
    type: MainActionTypes.SET_CURRENCY_LIST,
    payload: payload
  }
}


