import {
  IComboBox,
  IComboBoxOption,
  Icon, TextField,
  Toggle,
} from "@fluentui/react";
import {loadAccountList, loadCompanyList, loadCurrencyList} from "@taskpane/core/actionCreators";
import { ApiService } from "@taskpane/core/services";
import { useAppDispatch, useAppSelector } from "@taskpane/core/store";
import { Helper } from "@taskpane/helpers";
import { useDisplayFormulasMode } from "@taskpane/hooks";
import { setColor } from "@taskpane/theme";
import { onFormatDate, onParseDateFromString} from "@taskpane/utils";
import moment from "moment";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useServices } from "src/taskpane/core/tools";

import ChoiceFormulaWrapper from "../../../features/choiceFormulaWrapper";
import { GLBalanceFormula } from "../../../features/glBalanceFormula";
import {Button, PMComboBox, ToggleDate} from "../../atoms";
import {PMDatePicker} from "../../atoms/datePIcker";

interface GLBalanceWizardProps {
  onBackClick: () => void;
}

let parsedFormulaInst = null;

export const GLBalanceWizard = forwardRef((props: GLBalanceWizardProps, ref) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [activeCell, setActiveCell] = useState<Excel.Range>(null);

  const [selCompany, setSelCompany] = useState("");
  const [selCompanyText, setSelCompanyText] = useState("");
  const [account, setAccount] = useState("");
  const [currency, setCurrency] = useState("");
  const [withProvisionalEntries, setWithProvisionalEntries] = useState(true);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateFromCell, setDateFromCell ] = useState("");
  const [dateTo, setDateTo] = useState(null);
  const [dateToCell, setDateToCell ] = useState("");
  const [debits, setDebits] = useState(true);
  const [credits, setCredits] = useState(true);

  const [toggleFromDate, setToggleFromDate] = useState(true);
  const [toggleToDate, setToggleToDate] = useState(true);

  const [originalCurrency, setOriginalCurrency] = useState(false);

  const displayFormulasMode = useDisplayFormulasMode();

  // error management
  const [selCompanyError, setSelCompanyError] = useState(null);
  const [accountError, setAccountError] = useState(null);
  const [fromDateError, setFromDateError] = useState(null);
  const [toDateError, setToDateError] = useState(null);

  const { companyList, accountList, currencyList } = useAppSelector((state) => state.main);
  const [companyListOptions, setCompanyListOptions] = useState([]);
  const [accountListOptions, setAccountListOptions] = useState([]);
  const [currencyListOptions, setCurrencyListOptions] = useState([]);

  const { cellMvtsLink } = useServices();

  const handleBackOnClick = () => {
    props.onBackClick();
  };

  const validate = () => {
    let result = true;
    if (!selCompanyText.trim()) {
      setSelCompanyError(t("pleaseChooseValue"));
      result = false;
    }

    if (!account) {
      setAccountError(t("pleaseChooseValue"));
      result = false;
    }

    if (toggleFromDate) {
      if (!dateFrom) {
        setFromDateError(t("pleaseEnterValue"));
        result = false;
      }

      if (dateFrom > dateTo && toggleToDate) {
        setFromDateError(t("dateFromCannotBeAfterDateTo"));
        result = false;
      }
    }

    if (toggleToDate) {
      if (!dateTo) {
        setToDateError(t("pleaseEnterValue"));
        result = false;
      }
    }

    if (!dateFromCell && !toggleFromDate) {
      setFromDateError(t("pleaseEnterValue"));
      result = false;
    }

    if (!dateToCell && !toggleToDate) {
      setToDateError(t("pleaseEnterValue"));
      result = false;
    }

    return result;
  };

  const onOkClick = async () => {
    try {
      if (!validate()) return;

      let actualAccount = account;
      let actualSelCompany = selCompany;
      let actualCurrency = currency;

      await Excel.run(async (context) => {
        if ((await Helper.isCellRef(selCompany, context)).isCellRef) {
          actualSelCompany = Helper.decorateWithExcelConcat(selCompany);
        }

        if ((await Helper.isCellRef(account, context)).isCellRef) {
          actualAccount = Helper.decorateWithExcelConcat(account);
        }

        if ((await Helper.isCellRef(currency, context)).isCellRef) {
          actualCurrency = Helper.decorateWithExcelConcat(currency);
        }
      });

      const formula = new GLBalanceFormula(new ApiService());
      formula.formulaData = {
        companyId: actualSelCompany,
        fromDate: !toggleFromDate ? Helper.decorateWithExcelConcat(dateFromCell) : moment(dateFrom).format("MM-DD-YYYY"),
        toDate: !toggleToDate ? Helper.decorateWithExcelConcat(dateToCell) : moment(dateTo).format("MM-DD-YYYY"),
        account: actualAccount,
        currency: actualCurrency,
        withProvisionalEntries: withProvisionalEntries.toString(),
        originalCurrency: originalCurrency.toString(),
        debits: debits.toString(),
        credits: credits.toString(),
      };
      await Excel.run(async (context: Excel.RequestContext) => {
        const cell = context.workbook.getActiveCell();
        if (cell) {
          const formulaValue = new ChoiceFormulaWrapper().wrap(displayFormulasMode, 0, formula.generate());

          cell.formulas = [[formulaValue]];
          //await cellMvtsLink.putLinkIntoCell(cell, formulaValue);
          props.onBackClick();
        }
      });
    } catch (e) {
      console.error("GLBalanceWizard:OkClick", e);
    }
  };

  const onCompanyChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    parsedFormulaInst = null;
    if (option) {
      setSelCompany(option.key as string);
      setSelCompanyText(option.text);
    } else if (value) {
      setSelCompanyText(value);
      setSelCompany(value);
    }

    if (!option) return;
    const company = companyList.find((c) => c.ID === option.key);
    if (company) {
      setDateFrom(moment(company.StartDate).toDate());
      setDateTo(moment(company.EndDate).toDate());

      dispatch(loadAccountList(company.ID));
      dispatch(loadCurrencyList(company.ID));
    }
  };

  function onAccountChange(event: React.FormEvent<IComboBox>, option: IComboBoxOption, index: number, value: string) {
    if (option) {
      const foundItem = accountList.find(e => e.ID === option.key);
      if (foundItem) {
        setAccount(foundItem.AccountNumber);
        setCurrency(foundItem.CurrencyCode);
      }
    }
    else if (value) setAccount(value);
  }

  function onSelectFromDate(date: Date | null | undefined) {
    setDateFrom(date);
  }

  function onSelectToDate(date: Date | null | undefined) {
    setDateTo(date);
  }

  function onProvisionalEntriesToggle() {
    setWithProvisionalEntries(!withProvisionalEntries);
  }

  async function loadActiveCell() {
    await Excel.run(async (context) => {
      const cell = context.workbook.getActiveCell();
      cell.load("formulas, values");
      await context.sync();
      setActiveCell(cell);
    });
  }

  useEffect(() => {
    dispatch(loadCompanyList(true));
    loadActiveCell();
  }, [dispatch]);

  useEffect(() => {
    parsedFormulaInst = null;
    if (activeCell) {
      const fWrapper = new ChoiceFormulaWrapper();
      const unwrapped = fWrapper.unwrap(activeCell.formulas[0][0]);
      if (unwrapped) {
        parsedFormulaInst = new GLBalanceFormula(null).parse(unwrapped.formula) as GLBalanceFormula;
        if (parsedFormulaInst) {
          const val = Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.companyId)
            ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.companyId)
            : parsedFormulaInst.formulaData.companyId;
          const company = companyList.find(c => c.ID === val);
          if (company) {
            setSelCompanyText(company.FiscalYearDescription);
            setSelCompany(company.ID);
            dispatch(loadAccountList(company.ID));
            dispatch(loadCurrencyList(company.ID));
          }
          else {
            setSelCompanyText(val);
            setSelCompany(null);
          }

          setAccount(
            Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.account)
              ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.account)
              : parsedFormulaInst.formulaData.account
          );

          setCurrency(
            Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.currency)
              ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.currency)
              : parsedFormulaInst.formulaData.currency
          );

          if (Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.fromDate)) {
            setDateFromCell(Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.fromDate));
            setToggleFromDate(false);
            setDateFrom(null);
          } else {
            setDateFromCell(null);
            setToggleFromDate(true);
            setDateFrom(moment(parsedFormulaInst.formulaData.fromDate).toDate());
          }

          if (Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.toDate)) {
            setDateToCell(Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.toDate));
            setToggleToDate(false);
            setDateTo(null);
          } else {
            setDateToCell("");
            setToggleToDate(true);
            setDateTo(moment(parsedFormulaInst.formulaData.toDate).toDate());
          }

          setWithProvisionalEntries(parsedFormulaInst.formulaData.withProvisionalEntries === "true");
          setOriginalCurrency(parsedFormulaInst.formulaData.originalCurrency === "true");
        }
      }
    }
  }, [activeCell, companyList, companyListOptions]);

  useEffect(() => {
    setCompanyListOptions(companyList.map((element) => ({ key: element.ID, text: element.FiscalYearDescription })));
  }, [companyList, setCompanyListOptions]);

  useEffect(() => {
    if (accountList && accountList.length > 0) {
      setAccountListOptions(accountList.map((e) => ({ key: e.ID, text: e.DisplayFormatedString })));
    }
  }, [accountList]);

  useEffect(() => {
    if (!parsedFormulaInst) return;
    setAccount(
      Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.account)
        ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.account)
        : parsedFormulaInst.formulaData.account
    );
  }, [accountListOptions]);

  useEffect(() => {
    if (currencyList && currencyList.length > 0) {
      setCurrencyListOptions(currencyList.map((e) => ({ key: e.Code, text: e.Code })));
    }
  }, [currencyList]);

  useEffect(() => {
    if (!parsedFormulaInst) return;
    setCurrency(
      Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.currency)
        ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.currency)
        : parsedFormulaInst.formulaData.currency
    );
  }, [currencyListOptions]);

  function onDateFromCellChange(event, newValue) {
    setDateFromCell(newValue);
  }

  function onDateToCellChange(event, newValue) {
    setDateToCell(newValue);
  }

  function onToggleFromDate(event, checked) {
    setFromDateError(null);
    setToggleFromDate(checked);
  }

  function onToggleToDate(event, checked) {
    setToDateError(null);
    setToggleToDate(checked);
  }

  const fromDateComp = (<>
    <PMDatePicker
      allowTextInput
      onSelectDate={onSelectFromDate}
      formatDate={onFormatDate}
      parseDateFromString={onParseDateFromString(dateTo)}
      value={dateFrom}
    />
  </>);

  const toDateComp = (<>
    <PMDatePicker
  allowTextInput
  onSelectDate={onSelectToDate}
  formatDate={onFormatDate}
  parseDateFromString={onParseDateFromString(dateTo)}
  value={dateTo}
  />
  </>);

  function onCurrencyChange(event: React.FormEvent<IComboBox>, option: IComboBoxOption, index: number, value: string) {
    if (option) {
      setCurrency(option.key as string);
    }
    else if (value) setCurrency(value);
  }

  function onOriginalCurrencyToggle() {
    setOriginalCurrency(!originalCurrency);
  }

  function onDebitsChange(event, checked) {
    if (!credits && !checked) setDebits(true)
    else setDebits(checked);
  }

  function onCreditsChange(event, checked) {
    if (!debits && !checked) setCredits(true)
    else setCredits(checked);
  }

  return (
    <div className="pt-4 col col-12">
      <div className="h-100 document">
        <div onClick={() => handleBackOnClick()} style={{ cursor: "pointer" }} className="d-flex">
          <Icon iconName="Back" className="ms-font-l" style={{ color: setColor("blue") }} />
          <span className="ms-font-l ms-2" style={{ color: setColor("blue") }}>
            {t("back")}
          </span>
        </div>

        <div className="mt-3 mb-2 ms-fontWeight-bold">
          <span>{t("glBalanceFormula")}</span>
        </div>

        <div className="mb-2">
          <PMComboBox
            label={t("company")}
            selectedKey={selCompany}
            options={companyListOptions}
            text={selCompanyText}
            onChange={onCompanyChange}
          ></PMComboBox>
          {selCompanyError ? <span style={{ color: setColor("red") }}>{selCompanyError}</span> : ""}
        </div>

        <div className="mb-2">
          <PMComboBox
            label={t("account")}
            text={account}
            onChange={onAccountChange}
            options={accountListOptions}
          ></PMComboBox>
          {accountError ? <span style={{ color: setColor("red") }}>{accountError}</span> : ""}
        </div>

        <div className="mb-2">
          <PMComboBox
            label={t("currency")}
            text={currency}
            onChange={onCurrencyChange}
            options={currencyListOptions}
          ></PMComboBox>
        </div>

        <div className="mb-2">
          <div className="row">
            <ToggleDate
              label={t("fromDate")}
              checked={toggleFromDate}
              onChange={onToggleFromDate}/>
            { toggleFromDate ?
              (<div className="col-9">
                {fromDateComp}
              </div>)
              :
              (<div className="col-3">
                <TextField
                  value={dateFromCell}
                  onChange={onDateFromCellChange}
                />
              </div>)
            }
            {fromDateError ? <span style={{ color: setColor("red") }}>{fromDateError}</span> : ""}
          </div>
        </div>
        <div className="mb-2">
          <div className="row">
            <ToggleDate
              label={t("toDate")}
              onChange={onToggleToDate}
              checked={toggleToDate}/>
            { toggleToDate ?
              <div className="col-9">
                { toDateComp }
              </div> :
              <div className="col-3">
                <TextField
                  value={dateToCell}
                  onChange={onDateToCellChange}
                />
              </div> }
            {toDateError ? <span style={{ color: setColor("red") }}>{toDateError}</span> : ""}
          </div>
        </div>
        <div className="mb-2">
          <Toggle
            label={t("originalCurrency")}
            onClick={() => onOriginalCurrencyToggle()}
            checked={originalCurrency}
          ></Toggle>
        </div>
        <div className="mb-2">
          <Toggle
            label={t("includeProvisionalEntries")}
            onClick={() => onProvisionalEntriesToggle()}
            checked={withProvisionalEntries}
          ></Toggle>
        </div>

        <div className="mb-2">
          <Toggle label={t("debits")} checked={debits} onChange={onDebitsChange}/>
        </div>
        <div className="mb-2">
          <Toggle label={t("credits")} checked={credits} onChange={onCreditsChange}/>
        </div>

        <div className="mb-2">
          <Button label={t("validate")} onClick={onOkClick} />
        </div>
      </div>
    </div>
  );
});

