import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import {buttonStyle} from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {faChevronLeft, faChevronRight, faDownload} from "@fortawesome/free-solid-svg-icons";
import {css, cx} from '@emotion/css';
import {Tooltip} from "@progress/kendo-react-tooltip";
import {useTranslation} from "react-i18next";
import {useServices} from "../../../core/tools";

const toolbarStyle = css`
  padding: 3px;
  width: 100%;
  position: fixed;
  background-color: #F5F5F5;
`;

export default function PdfViewer(props: {pdfId: string, onClose}){
  const canvasRef = useRef(null);
  const { apiService } = useServices();
  const {t, i18n} = useTranslation();

  const [pdfRef, setPdfRef] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const renderPage = useCallback((pageNum, pdf=pdfRef) => {
    pdf && pdf.getPage(pageNum).then(function(page) {
      const viewport = page.getViewport({scale: 1.5});
      const canvas = canvasRef.current;
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderContext = {
        canvasContext: canvas.getContext('2d'),
        viewport: viewport
      };
      page.render(renderContext);
    });
  }, [pdfRef]);

  useEffect(() => {
    renderPage(currentPage, pdfRef);
  }, [pdfRef, currentPage, renderPage]);

  async function downloadAndShowPdf() {
    try {
      const dataBlob = await apiService.storageItemDownload(props.pdfId);
      if (dataBlob.ok) {
        const data = await (dataBlob.parsedBody as Blob).arrayBuffer();
        const loadedPdf = await pdfjsLib.getDocument(data).promise;
        setPdfRef(loadedPdf)
      } else console.error('downloadAndShowPdf ', dataBlob.status, dataBlob.parsedBody as string);
    } catch (e) {
      console.error('downloadAndShowPdf ', e);
    }
  }
  useEffect(() => {
    downloadAndShowPdf();
  }, [props.pdfId]);

  const nextPage = () => pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

  const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

  async function downloadPdf() {
    try {
      const dataBlob = await apiService.storageItemDownload(props.pdfId);
      if (!dataBlob.ok) {
        console.error('downloadPdf ', dataBlob.status, dataBlob.parsedBody as string);
        return;
      }

      const url = window.URL.createObjectURL(
        new Blob([dataBlob.parsedBody as Blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `download.pdf`,
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (e) {
      console.error('downloadPdf ', e);
    }
  }

  return (<>
    <div className={cx('d-flex','flex-row', 'pa-0', 'ma-0', toolbarStyle)}>
      <Tooltip parentTitle={true}>
        <span title={t('displayPdf.prevPage')}>
          <button className={buttonStyle}
                  onClick={prevPage}>
            <FontAwesomeIcon
              style={currentPage === 1 ? {opacity: 0.4} : {}}
              icon={faChevronLeft as IconProp} />
          </button>
        </span>
        <span title={t('displayPdf.nextPage')}>
          <button className={buttonStyle} onClick={nextPage}>
            <FontAwesomeIcon
              style={pdfRef != undefined && currentPage === pdfRef.numPages ? {opacity: 0.4} : {}}
              icon={faChevronRight as IconProp} />
          </button>
        </span>
        <span title={t('displayPdf.download')}>
          <button className={buttonStyle} onClick={downloadPdf}><FontAwesomeIcon icon={faDownload as IconProp} /></button>
        </span>
      </Tooltip>
    </div>
    <canvas ref={canvasRef}></canvas>
  </>);
}
