import React from 'react';
import {DatePicker, defaultDatePickerStrings, IDatePickerProps, IDatePickerStrings} from "@fluentui/react";
import {useTranslation} from "react-i18next";


export const PMDatePicker = (props: IDatePickerProps) => {
  const { t, i18n } = useTranslation();
  const datePickerStrings : IDatePickerStrings = {
    months: t('months').split(','),
    shortMonths: t('shortMonths').split(','),
    days: t('days').split(','),
    shortDays: t('shortDays').split(','),
    goToToday: t('goToToday'),
    prevMonthAriaLabel: t('goToPreviousMonth'),
    nextMonthAriaLabel: t('goToNextMonth'),
    prevYearAriaLabel: t('goToPreviousYear'),
    nextYearAriaLabel: t('goToNextYear')
  }
  return (<DatePicker strings={datePickerStrings} {...props}/>);
}
