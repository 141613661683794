import React from 'react';
import {updateDisplayFormulas} from "@taskpane/core/actionCreators";
import {FormulasMode} from "@taskpane/utils";
import FormulasModeClass from "../../../features/formulasModeClass";
import {Toggle} from "@fluentui/react";
import {useAppDispatch, useAppSelector} from "@taskpane/core/store";
import {useTranslation} from "react-i18next";

export const WorkToolsPage: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { displayFormulas } = useAppSelector((state) => state.main);

  async function onDisplayFormulasClick(event, checked) {

    dispatch(updateDisplayFormulas(checked));
    const formulasMode = checked ? FormulasMode.Formulas : FormulasMode.Amount;
    const cancellationToken = {};
    await Excel.run(async (context) => {
      await new FormulasModeClass().changeFormulasMode(
        formulasMode,
        (max, currentSheetIdentifier1) => {},
        (current) => {},
        (stoppedByUser) => {},
        cancellationToken,
        context,
      );
    });
  }

  return (
    <div className="pt-4">
      <div className="mt-1 mb-2 ms-fontWeight-bold">
        <span>{t("workToolsPage")}</span>
      </div>
      <Toggle label={t("displayFormulas")} checked={displayFormulas} onChange={onDisplayFormulasClick}></Toggle>
    </div>);

}

