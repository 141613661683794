import {
  PublicAccountDTO,
  PublicCurrencyDTO,
  PublicFiscalYearDTO,
  PublicTrialBalanceTreeListDTO,
} from "@taskpane/types";
import { request, setApiURL } from "@taskpane/utils";

import { Endpoints } from "../endpoints";
import { IApiService } from "./interfaces/IAPI.service";
import {PublicEntryLineDTO} from "../../@types/publicEntryLineDto";

export class ApiService implements IApiService {
  async getFiscalYearsAsync() {
    return await request.get<Array<PublicFiscalYearDTO>>(setApiURL(Endpoints.getFiscalYears()));
  }

  async getFiscalYearAsync(fiscalYearId: string) {
    return await request.get<PublicFiscalYearDTO>(setApiURL(Endpoints.getFiscalYear(fiscalYearId)));
  }

  async getCOA(fiscalYearId: string) {
    return await request.get<Array<PublicAccountDTO>>(setApiURL(Endpoints.getCOA(fiscalYearId)));
  }

  async getCurrencies(fiscalYearId: string) {
    return await request.get<Array<PublicCurrencyDTO>>(setApiURL(Endpoints.getCurrencies(fiscalYearId)));
  }

  async getTrialBalance(
    fiscalYearId: string,
    fromDate: string,
    toDate: string,
    fromAccount: string,
    toAccount: string,
    ccyCode: string,
    withZeroAccounts: boolean,
    withInitBal: boolean,
    withMovements: boolean,
    withOrigCcy: boolean,
    withProvisionalEntries: boolean = true,
    debits: boolean = true,
    credits: boolean = true,
  ) {
    return await request.get<number>(setApiURL(Endpoints.getTrialBalance(fiscalYearId)), {
      params: {
        from_date: fromDate,
        to_date: toDate,
        from_account: fromAccount,
        to_account: toAccount,
        currency_code: ccyCode,
        with_zero_accounts: withZeroAccounts ? "true" : "false",
        with_movements: withMovements ? "true" : "false",
        with_initbal: withInitBal ? "true" : "false",
        with_orig_ccy: withOrigCcy ? "true" : "false",
        with_provisional_entries: withProvisionalEntries ? "true" : "false",
        debits: debits ? "true" : "false",
        credits: credits ? "true" : "false",
      },
    });
  }

  async getTrialBalanceMvt(
    fiscalYearId: string,
    fromDate: string,
    toDate: string,
    fromAccount: string,
    toAccount: string,
    ccyCode: string,
    withZeroAccounts: boolean,
    withInitBal: boolean,
    withMovements: boolean,
    withProvisionalEntries: boolean = true,
    debits: boolean = true,
    credits: boolean = true,
  ): Promise<Response & {parsedBody?: PublicEntryLineDTO[] }> {
    return await request.get<PublicEntryLineDTO[]>(setApiURL(Endpoints.getTrialBalanceMvts(fiscalYearId)), {
      params: {
        from_date: fromDate,
        to_date: toDate,
        from_account: fromAccount,
        to_account: toAccount,
        currency_code: ccyCode,
        with_zero_accounts: withZeroAccounts ? "true" : "false",
        with_movements: withMovements ? "true" : "false",
        with_initbal: withInitBal ? "true" : "false",
        with_provisional_entries: withProvisionalEntries ? "true" : "false",
        debits: debits ? "true" : "false",
        credits: credits ? "true" : "false",
      },
    });
  }

  async getBalanceSheetTree(
    fiscalYearId: string,
    asOnDate: string,
    fromAccountCode: string,
    toAccountCode: string,
    ccyCode: string,
    withZeroAccounts: boolean
  ) {
    return await request.get<Array<PublicTrialBalanceTreeListDTO>>(
      setApiURL(Endpoints.getBalanceSheetTree(fiscalYearId)),
      {
        params: {
          as_on: asOnDate,
          from_account: fromAccountCode,
          to_account: toAccountCode,
          currency_code: ccyCode,
          with_zero_accounts: withZeroAccounts ? "true" : "false",
        },
      }
    );
  }

  async getProfitAndLossTree(
    fiscalYearId: string,
    fromDate: string,
    toDate: string,
    fromAccount: string,
    toAccount: string,
    ccyCode: string,
    withZeroAccounts: boolean
  ) {
    return await request.get<Array<PublicTrialBalanceTreeListDTO>>(
      setApiURL(Endpoints.getProfitAndLossTree(fiscalYearId)),
      {
        params: {
          from_date: fromDate,
          to_date: toDate,
          from_account_code: fromAccount,
          to_account_code: toAccount,
          currency_code: ccyCode,
          with_zero_accounts: withZeroAccounts ? "true" : "false",
        },
      }
    );
  }

  async storageItemDownload(id: string) {
    return await request.get<string | Blob>(setApiURL(Endpoints.storageItemDownload(id)),
      { params: {} },
      null,
      null,
      'application/octet-stream',
      true);
  }
}
