import { AccountGroupingType, AccountNature, PublicTrialBalanceTreeListDTO } from "@taskpane/types";

import { BalanceSheetPLBase, BalanceSheetPLError, TotalCellRanges } from "./balanceSheetPLBase";

import i18n from "i18next";

export default class BalanceSheet extends BalanceSheetPLBase {
  private _assetsCell: string;
  private _liabilitiesCell: string;
  protected async onProcessTotalCellRanges(tots: TotalCellRanges) {
    if (tots.groupingType === AccountGroupingType.Class) {
      const sh = this._context.workbook.worksheets.getActiveWorksheet();
      switch (tots.nature) {
        case AccountNature.Assets: {
          const cell = sh.getCell(this.CurLine, this.CurCol + 4);
          cell.load("address");
          await this._context.sync();
          this._assetsCell = cell.address;
          break;
        }
        case AccountNature.Liabilities: {
          const cell = sh.getCell(this.CurLine, this.CurCol + 4);
          cell.load("address");
          await this._context.sync();
          this._liabilitiesCell = cell.address;
          break;
        }
      }
    }
  }

  async generate(
    list: Array<PublicTrialBalanceTreeListDTO>,
    fyCode: string,
    fyDesc: string,
    dateFrom: Date,
    dateTo: Date,
    withOrigCurrency: boolean,
    refCurrency: string,
    excelContext: Excel.RequestContext
  ): Promise<void> {
    await super.generate(list, fyCode, fyDesc, dateFrom, dateTo, withOrigCurrency, refCurrency, excelContext);
    const sh = this._context.workbook.worksheets.getActiveWorksheet();

    if (!this._assetsCell || !this._liabilitiesCell) {
      this.lastError = BalanceSheetPLError.structureError;
      return;
    }

    this.CurLine++;
    let cell = sh.getCell(this.CurLine, this.CurCol + 4);
    cell.load("address");
    await this._context.sync();

    const resultCellAddress = cell.address;
    cell.formulas = [[`=-(${this._assetsCell}+${this._liabilitiesCell})`]];

    cell = sh.getCell(this.CurLine, this.CurCol);
    cell.formulas = [[`=IF(${resultCellAddress} < 0, "${i18n.t("profit")}","${i18n.t("loss")}")`]];
    cell.format.font.bold = true;
  }
}
