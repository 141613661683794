import React, { CSSProperties, ReactNode } from "react";

export interface TemplateViewProps {
  children: React.ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  className?: string;
}

const styles: { [key: string]: CSSProperties } = {
  wrapper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    alignSelf: "center",
    height: "100%",
    width: "100%",
    padding: "0 10px",
  },
};

export const TemplateView = ({ header, footer, children, className = "" }: TemplateViewProps) => {
  return (
    <div className={`h-100 d-flex flex-column ${className}`}>
      {header && header}
      <div style={styles.wrapper}>{children}</div>
      {footer && footer}
    </div>
    // <div className={`container-fluid h-100 ${className}`}>
    //   <div className="row d-flex h-100">
    //     {header && header}
    //     {children}
    //     {footer && footer}
    //   </div>
    // </div>
  );
};
