
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import './i18n';
import { App } from "@taskpane/components";
import { persistor, store } from "@taskpane/core/store";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import '@progress/kendo-theme-default/dist/all.css';
import styles from './main.scss';
import {createRoot} from "react-dom/client";

const dummy = styles.dummy;

initializeIcons();

let isOfficeInitialized = false;

const MOUNT_NODE = document.getElementById("container");

const render = (Component: typeof App) => {
  createRoot(MOUNT_NODE).render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Component isOfficeInitialized={isOfficeInitialized} />
      </PersistGate>
    </Provider>
  );
};

/* Render application after Office initializes */
Office.initialize = () => {
  isOfficeInitialized = true;
  render(App);
};

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { App } = require("./components/App");
    render(App);
  });
}
