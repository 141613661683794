import { ApiService } from "@taskpane/core/services";
import moment from "moment-msdate";

import { IApiService } from "../core/services/interfaces/IAPI.service";
import IFormulaBase from "./formulaBase";
import {makeDisplayMvtsLink} from "@taskpane/utils";

export interface GLRangeFormulaData {
  companyId: string;
  fromAccount: string;
  toAccount: string;
  currency: string;
  fromDate: string;
  toDate: string;
  withZeroAccounts: string;
  withOpeningBalance: string;
  withMvts: string;
  originalCurrency: string;
  debits: string;
  credits: string;
}

export default class GLRangeFormula implements IFormulaBase {
  constructor(private apiService: IApiService) {}
  private name = "PMGLRange";
  formulaData: GLRangeFormulaData;

  generate() {
    const data = this.formulaData;
    return `#${this.name}/${data.companyId}/${data.fromAccount}/${data.toAccount}/{${data.fromDate}}/{${data.toDate}}/${data.currency}/${data.withOpeningBalance}/${data.withMvts}/${data.originalCurrency}/${data.debits}/${data.credits}`;
  }

  async getAmount(): Promise<number | string> {
    const data = this.formulaData;
    const resHttp = await this.apiService.getTrialBalance(
      data.companyId,
      data.fromDate,
      data.toDate,
      data.fromAccount,
      data.toAccount,
      data.currency,
      data.withZeroAccounts === "true",
      data.withOpeningBalance === "true",
      data.withMvts === "true",
      data.originalCurrency === "true",
      true,
      data.debits === "true",
      data.credits === "true",
    );
    if (resHttp.ok) {
      return resHttp.parsedBody;
    } else {
      return "error while fetching amount";
    }
  }

  parse(value: string, apiService?: IApiService): IFormulaBase {
    if (!isNaN(<any>value)) return null;
    if (!value) return null;
    if (!value.startsWith("#" + this.name)) return null;
    const formulaInst = new GLRangeFormula(apiService !== undefined ? apiService : new ApiService());
    let tvalue = value;
    let match = tvalue.match(/\{[0-9,/-]*\}/);

    while (match && match.length > 0) {
      const concatValue = match[0]
        .substring(1, match[0].length - 1);

      tvalue =
        tvalue.substring(0, tvalue.indexOf(match[0])) +
        (!isNaN(<any>concatValue) ? concatValue :
          concatValue.replace(/\//g, "dateSep")
          .replace(",", ".")) +
        tvalue.substring(tvalue.indexOf(match[0]) + match[0].length);
      match = tvalue.match(/\{[0-9,/-]*\}/);
    }

    const values = tvalue.split("/");
    const dateFromString = !isNaN(<any>values[4]) ? values[4] : values[4].replace(/dateSep/g, "/");
    const dateToString = !isNaN(<any>values[5]) ? values[5] : values[5].replace(/dateSep/g, "/");

    formulaInst.formulaData = {
      companyId: values[1],
      fromAccount: values[2],
      toAccount: values[3],
      fromDate: !isNaN(<any>dateFromString) ? moment.fromOADate(dateFromString).format("MM-DD-YYYY") : dateFromString,
      toDate: !isNaN(<any>dateToString) ? moment.fromOADate(dateToString).format("MM-DD-YYYY") : dateToString,
      currency: values[6],
      withZeroAccounts: "true",
      withOpeningBalance: values[7].toLowerCase().trim(),
      withMvts: values[8].toLowerCase().trim(),
      originalCurrency: values[9].toLowerCase().trim(),
      debits: values.length > 10 ? values[10].toLowerCase().trim() : "true",
      credits: values.length > 11 ? values[11].toLowerCase().trim() : "true",
    };
    return formulaInst;
  }

  getDisplayMvtsLink(): string {
    return makeDisplayMvtsLink(this.formulaData.companyId,
      this.formulaData.fromDate,
      this.formulaData.toDate,
      this.formulaData.fromAccount,
      this.formulaData.toAccount,
      this.formulaData.currency,
      this.formulaData.withZeroAccounts === 'true',
      this.formulaData.withOpeningBalance === 'true',
      this.formulaData.withMvts === 'true',
      true,
      this.formulaData.debits === 'true',
      this.formulaData.credits === 'true');
  }

  async getMvts() {
    try {
      return await this.apiService.getTrialBalanceMvt(this.formulaData.companyId,
        this.formulaData.fromDate,
        this.formulaData.toDate,
        this.formulaData.fromAccount,
        this.formulaData.toAccount,
        this.formulaData.currency,
        this.formulaData.withZeroAccounts === 'true',
        this.formulaData.withOpeningBalance === 'true',
        this.formulaData.withMvts === 'true',
        true,
        this.formulaData.debits === 'true',
        this.formulaData.credits === 'true'
      );
    } catch (e) {
      console.error('glRangeFormula.getMvts', e);
      throw new Error(e);
    }
  }
}
