import { Icon, TextField, Toggle } from "@fluentui/react";
import { loadAccountList, loadCompanyList, loadCurrencyList } from "@taskpane/core/actionCreators";
import { ApiService } from "@taskpane/core/services";
import { useAppDispatch, useAppSelector } from "@taskpane/core/store";
import { Helper } from "@taskpane/helpers";
import { useDisplayFormulasMode } from "@taskpane/hooks";
import { setColor } from "@taskpane/theme";
import { onFormatDate, onParseDateFromString } from "@taskpane/utils";
import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ChoiceFormulaWrapper from "../../../features/choiceFormulaWrapper";
import GLRangeFormula from "../../../features/glRangeFormula";
import {Button, PMComboBox, ToggleDate} from "../../atoms";
import {PMDatePicker} from "../../atoms/datePIcker";
import {useServices} from "../../../core/tools";

interface GLRangeWizardProps {
  onBackClick: () => void;
}

let parsedFormulaInst = null;

export const GLRangeWizard = forwardRef((props: GLRangeWizardProps, ref) => {
  const { t, i18n } = useTranslation();

  const [selCompany, setSelCompany] = useState("");
  const [selCompanyText, setSelCompanyText] = useState("");
  const [fromAccount, setFromAccount] = useState("");
  const [toAccount, setToAccount] = useState("");
  const [currency, setCurrency] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateFromCell, setDateFromCell] = useState("");
  const [dateTo, setDateTo] = useState(new Date());
  const [dateToCell, setDateToCell] = useState("");
  const [activeCell, setActiveCell] = useState<Excel.Range>(null);

  const [toggleFromDate, setToggleFromDate] = useState(true);
  const [toggleToDate, setToggleToDate] = useState(true);

  const [originalCurrency, setOriginalCurrency] = useState(false);
  const [withOpeningBalance, setWithOpeningBalance] = useState(true);
  const [withMvts, setWithMvts] = useState(true);
  const [debits, setDebits] = useState(true);
  const [credits, setCredits] = useState(true);

  // error management
  const [selCompanyError, setSelCompanyError] = useState(null);
  const [fromAccountError, setFromAccountError] = useState(null);
  const [toAccountError, setToAccountError] = useState(null);
  const [currencyError, setCurrencyError] = useState(null);
  const [fromDateError, setFromDateError] = useState(null);
  const [toDateError, setToDateError] = useState(null);

  const dispatch = useAppDispatch();
  const { companyList, accountList, currencyList } = useAppSelector((state) => state.main);
  const [companyListOptions, setCompanyListOptions] = useState([]);
  const [accountListOptions, setAccountListOptions] = useState([]);
  const [currencyListOptions, setCurrencyListOptions] = useState([]);

  const displayFormulasMode = useDisplayFormulasMode();

  const { cellMvtsLink } = useServices();

  const handleBackOnClick = () => {
    props.onBackClick();
  };

  const validate = () => {
    let result = true;
    if (!selCompanyText.trim()) {
      setSelCompanyError(t("pleaseChooseValue"));
      result = false;
    }

    if (!fromAccount) {
      setFromAccountError(t("pleaseChooseValue"));
      result = false;
    }

    if (!toAccount) {
      setToAccountError(t("pleaseChooseValue"));
      result = false;
    }

    if (toggleFromDate) {
      if (!dateFrom) {
        setFromDateError(t("pleaseEnterValue"));
        result = false;
      }

      if (dateFrom > dateTo && toggleToDate) {
        setFromDateError(t("dateFromCannotBeAfterDateTo"));
        result = false;
      }
    }

    if (toggleToDate) {
      if (!dateTo) {
        setToDateError(t("pleaseEnterValue"));
        result = false;
      }
    }

    if (!dateFromCell && !toggleFromDate) {
      setFromDateError(t("pleaseEnterValue"));
      result = false;
    }

    if (!dateToCell && !toggleToDate) {
      setToDateError(t("pleaseEnterValue"));
      result = false;
    }

    return result;
  };

  const onOkClick = async () => {
    try {
      if (!validate()) return;

      let actualFromAccount = fromAccount;
      let actualToAccount = toAccount;
      let actualSelCompany = selCompany;

      await Excel.run(async (context) => {
        if ((await Helper.isCellRef(selCompany, context)).isCellRef) {
          actualSelCompany = Helper.decorateWithExcelConcat(selCompany);
        }

        if ((await Helper.isCellRef(fromAccount, context)).isCellRef) {
          actualFromAccount = Helper.decorateWithExcelConcat(fromAccount);
        }

        if ((await Helper.isCellRef(toAccount, context)).isCellRef) {
          actualToAccount = Helper.decorateWithExcelConcat(toAccount);
        }
      });
      const formula = new GLRangeFormula(new ApiService());
      formula.formulaData = {
        companyId: actualSelCompany,
        withMvts: withMvts.toString(),
        withOpeningBalance: withOpeningBalance.toString(),
        fromDate: dateFromCell ? Helper.decorateWithExcelConcat(dateFromCell) : moment(dateFrom).format("MM-DD-YYYY"),
        toDate: dateToCell ? Helper.decorateWithExcelConcat(dateToCell) : moment(dateTo).format("MM-DD-YYYY"),
        currency: currency,
        fromAccount: actualFromAccount,
        toAccount: actualToAccount,
        originalCurrency: originalCurrency.toString(),
        withZeroAccounts: "true",
        debits: debits.toString(),
        credits: credits.toString(),
      };
      await Excel.run(async (context: Excel.RequestContext) => {
        const cell = context.workbook.getActiveCell();
        if (cell) {
          const formulaValue = new ChoiceFormulaWrapper().wrap(displayFormulasMode, 0, formula.generate());
          cell.formulas = [[formulaValue]];
          // await cellMvtsLink.putLinkIntoCell(cell, formulaValue);
          props.onBackClick();
        }
      });
    } catch (e) {
      console.error("GLRangeWizard:OkClick", e);
    }
  };

  const onCompanyChange = async (event, option, index, value) => {
    parsedFormulaInst = null;
    if (option) {
      setSelCompany(option.key);
      setSelCompanyText(option.text);
    } else if (value) {
      setSelCompany(value);
      setSelCompanyText(value);
    }

    if (!option) return;

    const company = companyList.find((c) => c.ID === option.key);
    if (company) {
      setDateFrom(moment(company.StartDate).toDate());
      setDateTo(moment(company.EndDate).toDate());
      dispatch(loadAccountList(company.ID));
      dispatch(loadCurrencyList(company.ID));
    }
  };

  async function loadActiveCell() {
    await Excel.run(async (context) => {
      const cell = context.workbook.getActiveCell();
      cell.load("formulas, values");
      await context.sync();
      setActiveCell(cell);
    });
  }


  useEffect(() => {
    dispatch(loadCompanyList(true));
    loadActiveCell();
  }, [dispatch]);

  useEffect(() => {
    parsedFormulaInst = null;
    if (activeCell) {
      const fWrapper = new ChoiceFormulaWrapper();
      const unwrapped = fWrapper.unwrap(activeCell.formulas[0][0]);
      if (unwrapped) {
        parsedFormulaInst = new GLRangeFormula(null).parse(unwrapped.formula) as GLRangeFormula;
        if (parsedFormulaInst) {
          const val = Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.companyId)
            ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.companyId)
            : parsedFormulaInst.formulaData.companyId;
          const company = companyList.find(c => c.ID === val);
          if (company) {
            setSelCompanyText(company.FiscalYearDescription);
            setSelCompany(company.ID);
            dispatch(loadAccountList(company.ID));
            dispatch(loadCurrencyList(company.ID));
          }
          else {
            setSelCompanyText(val);
            setSelCompany(null);
          }

          setFromAccount(
            Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.fromAccount)
              ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.fromAccount)
              : parsedFormulaInst.formulaData.fromAccount
          );
          setToAccount(
            Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.toAccount)
              ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.toAccount)
              : parsedFormulaInst.formulaData.toAccount
          );

          setCurrency(
            Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.currency)
              ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.currency)
              : parsedFormulaInst.formulaData.currency
          );

          if (Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.fromDate)) {
            setDateFromCell(Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.fromDate));
            setToggleFromDate(false);
            setDateFrom(null);
          } else {
            setDateFromCell(null);
            setToggleFromDate(true);
            setDateFrom(moment(parsedFormulaInst.formulaData.fromDate).toDate());
          }

          if (Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.toDate)) {
            setDateToCell(Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.toDate));
            setToggleToDate(false);
            setDateTo(null);
          } else {
            setDateToCell("");
            setToggleToDate(true);
            setDateTo(moment(parsedFormulaInst.formulaData.toDate).toDate());
          }

          setOriginalCurrency(parsedFormulaInst.formulaData.originalCurrency === "true");
          setWithOpeningBalance(parsedFormulaInst.formulaData.withOpeningBalance === "true");
          setWithMvts(parsedFormulaInst.formulaData.withMvts === "true");
          setDebits(parsedFormulaInst.formulaData.debits === "true");
          setCredits(parsedFormulaInst.formulaData.credits === "true");
        }
      }
    }
  }, [activeCell, companyList, companyListOptions]);

  useEffect(() => {
    setCompanyListOptions(companyList.map((element) => ({ key: element.ID, text: element.FiscalYearDescription})));
  }, [companyList]);

  useEffect(() => {
    if (accountList && accountList.length > 0) {
      setAccountListOptions(accountList.map((e) => ({ key: e.AccountNumber, text: e.DisplayFormatedString })));
    }
  }, [accountList]);

  useEffect(() => {
    if (!parsedFormulaInst) return;
    setFromAccount(
      Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.fromAccount)
        ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.fromAccount)
        : parsedFormulaInst.formulaData.fromAccount
    );
    setToAccount(
      Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.toAccount)
        ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.toAccount)
        : parsedFormulaInst.formulaData.toAccount
    );
  }, [accountListOptions])

  useEffect(() => {
    if (currencyList && currencyList.length > 0) {
      setCurrencyListOptions(currencyList.map((e) => ({ key: e.Code, text: e.Code })));
    }
  }, [currencyList]);

  useEffect(() => {
    if (!parsedFormulaInst) return;
    setCurrency(
      Helper.hasExcelConcatDecoration(parsedFormulaInst.formulaData.currency)
        ? Helper.removeExcelConcatDecoration(parsedFormulaInst.formulaData.currency)
        : parsedFormulaInst.formulaData.currency
    );
  }, [currencyListOptions]);

  function onSelectFromDate(date: Date | null | undefined) {
    setDateFrom(date);
  }

  function onSelectToDate(date: Date | null | undefined) {
    setDateTo(date);
  }

  function onWithOpeningBalanceChange(event, checked) {
    setWithOpeningBalance(checked);
  }

  function onWithMvtsChange(event, checked) {
    setWithMvts(checked);
  }

  function onOriginalCurrencyChange(event, checked) {
    setOriginalCurrency(checked);
  }

  function onAccountFromChange(event, option, index, value) {
    if (option) {
      setFromAccount(option.key);
    } else if (value) {
      setFromAccount(value);
    }
  }

  function onCurrencyChange(event, option, index, value) {
    if (option) setCurrency(option.key)
    else if (value) setCurrency(value);
  }

  function onAccountToChange(event, option, index, value) {
    if (option) {
      setToAccount(option.key);
    } else if (value) {
      setToAccount(value);
    }
  }

  function onDateFromCellChange(event, newValue) {
    setDateFromCell(newValue);
  }

  function onDateToCellChange(event, newValue) {
    setDateToCell(newValue);
  }

  function onToggleFromDate(event, checked) {
    setFromDateError(null);
    setToggleFromDate(checked);
  }

  function onToggleToDate(event, checked) {
    setToDateError(null);
    setToggleToDate(checked);
  }

  function onDebitsChange(event, checked) {
    if (!credits && !checked) setDebits(true)
    else setDebits(checked);
  }

  function onCreditsChange(event, checked) {
    if (!debits && !checked) setCredits(true)
    else setCredits(checked);
  }

  const fromDateComp = (<>
    <PMDatePicker
        allowTextInput
        onSelectDate={onSelectFromDate}
        formatDate={onFormatDate}
        parseDateFromString={onParseDateFromString(dateTo)}
        value={dateFrom}
    />
  </>);

  const toDateComp = (<>
    <PMDatePicker
  allowTextInput
  onSelectDate={onSelectToDate}
  formatDate={onFormatDate}
  parseDateFromString={onParseDateFromString(dateTo)}
  value={dateTo}
  />
  </>);



  return (
    <div className="pt-4 col col-12">
      <div className="h-100 document">
        <div style={{ cursor: "pointer" }} className="d-flex">
          <Icon
            iconName="Back"
            className="ms-font-l"
            style={{ color: setColor("blue") }}
            onClick={() => handleBackOnClick()}
          />
          <span className="ms-font-l ms-2" style={{ color: setColor("blue") }} onClick={() => handleBackOnClick()}>
            {t("back")}
          </span>
        </div>

        <div className="mt-3 mb-2 ms-fontWeight-bold">
          <span>{t("glRangeFormula")}</span>
        </div>

        <div className="mb-2">
          <PMComboBox
            label={t("company")}
            text={selCompanyText}
            selectedKey={selCompany}
            options={companyListOptions}
            onChange={onCompanyChange}
          ></PMComboBox>
          {selCompanyError ? <span style={{ color: setColor("red") }}>{selCompanyError}</span> : ""}
        </div>

        <div className="mb-2">
          <PMComboBox
            label={t("accountFrom")}
            allowFreeform={true}
            onChange={onAccountFromChange}
            options={accountListOptions}
            text={fromAccount}
          ></PMComboBox>
          {fromAccountError ? <span style={{ color: setColor("red") }}>{fromAccountError}</span> : ""}
        </div>

        <div className="mb-2">
          <PMComboBox
  label={t("accountTo")}
  allowFreeform={true}
  text={toAccount}
  onChange={onAccountToChange}
  options={accountListOptions}
  />
          {toAccountError ? <span style={{ color: setColor("red") }}>{toAccountError}</span> : ""}
        </div>

        <div className="mb-2">
          <PMComboBox
            label={t("currency")}
            allowFreeform={true}
            text={currency}
            onChange={onCurrencyChange}
            options={currencyListOptions}
          ></PMComboBox>
          {currencyError ? <span style={{ color: setColor("red") }}>{currencyError}</span> : ""}
        </div>

        <div className="mb-2">
          <div className="row">
            <ToggleDate
              label={t("fromDate")}
              checked={toggleFromDate}
              onChange={onToggleFromDate}/>
            { toggleFromDate ?
              (<div className="col-9">
                {fromDateComp}
              </div>)
              :
              (<div className="col-3">
                <TextField
                  value={dateFromCell}
                  onChange={onDateFromCellChange}
                />
              </div>)
            }
            {fromDateError ? <span style={{ color: setColor("red") }}>{fromDateError}</span> : ""}
          </div>
        </div>
        <div className="mb-2">
          <div className="row">
            <ToggleDate
              label={t("toDate")}
              onChange={onToggleToDate}
              checked={toggleToDate}/>
            { toggleToDate ?
              <div className="col-9">
                { toDateComp }
            </div> :
            <div className="col-3">
              <TextField
                value={dateToCell}
                onChange={onDateToCellChange}
              />
            </div> }
            {toDateError ? <span style={{ color: setColor("red") }}>{toDateError}</span> : ""}
          </div>
        </div>
        <div className="mb-2">
          <Toggle
            label={t("openingBalance")}
            checked={withOpeningBalance}
            onChange={onWithOpeningBalanceChange}
          ></Toggle>
        </div>
        <div className="mb-2">
          <Toggle label={t("movements")} checked={withMvts} onChange={onWithMvtsChange}></Toggle>
        </div>
        <div className="mb-2">
          <Toggle label={t("originalCurrency")} checked={originalCurrency} onChange={onOriginalCurrencyChange}></Toggle>
        </div>
        <div className="mb-2">
          <Toggle label={t("debits")} checked={debits} onChange={onDebitsChange}></Toggle>
        </div>
        <div className="mb-2">
          <Toggle label={t("credits")} checked={credits} onChange={onCreditsChange}></Toggle>
        </div>

        <div className="mb-2">
          <Button label={t("validate")} onClick={onOkClick} />
        </div>
      </div>
    </div>
  );
});

