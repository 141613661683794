import { ApiKeyRequest } from "../../@types/apiKeyRequest";
import { ApiKeyRequestDTO } from "../../@types/apiKeyRequestDto";
import { ApiKeyRequestStatusDTO } from "../../@types/apiKeyRequestStatusDto";
import { request, setApiURL } from "../../tools/utils/fetch";
import { Endpoints } from "../endpoints";

export class AuthService {
  async postApiKeyRequest() {
    const identifier = `${Office.context.diagnostics.host.toString()}-${Office.context.diagnostics.platform.toString()}-${Office.context.diagnostics.version.toString()}`;
    return (
      await request.post<ApiKeyRequest, ApiKeyRequestDTO>(setApiURL(Endpoints.createApiKeyRequest()), {
        appName: "PMIziReportOnline",
        clientIdentifier: identifier,
      })
    ).parsedBody;
  }

  async getApiKeyRequestStatus(apiKeyRequestId: string) {
    return await request.get<ApiKeyRequestStatusDTO>(setApiURL(Endpoints.getApiKeyRequestStatus(apiKeyRequestId)));
  }
}
