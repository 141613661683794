import pLimit from "p-limit";

export enum LoadContextStatus {
  loadFormulasValues,
  resolveFormulas,
  write,
}

export type InitProgressCallback = (max: number, currentSheetIdentifier: string) => void;
export type ProcessingCallback = (
  value: string,
  formula: string,
  loadContextMode: LoadContextStatus,
  context: Excel.RequestContext
) => Promise<string> | string;
export type ProgressCallback = (current: number) => void;
export type EndProgressCallback = (stoppedByUser: boolean) => void;
export type StopCallback = () => boolean;

export class CellScanner {
  private _concurrencyLimit: number = 50;

  set concurrencyLimit(value: number) {
    this._concurrencyLimit = value
  }

  get concurrencyLimit(): number {
    return this._concurrencyLimit
  }

  async scan(
    callback: ProcessingCallback,
    initProgress: InitProgressCallback,
    progress: ProgressCallback,
    endProgress: EndProgressCallback,
    context: Excel.RequestContext,
    loadContextMode: LoadContextStatus,
    cancellationToken: any
  ) {
    const limit = pLimit(this._concurrencyLimit);

    cancellationToken.cancel = function () {
      cancellationToken.cancelled = true;
    }

    const sheets = context.workbook.worksheets;

    sheets.load("$none");
    await context.sync();

    let sheetCounter = 1;
    let stoppedByUser = false;
    for (const curSheet of sheets.items) {
      curSheet.load("name");
      await context.sync();

      const range = curSheet.getUsedRange();

      range.load("rowCount, columnCount, formulas, values");
      await context.sync();

      const maxRows = range.rowCount;
      const maxCols = range.columnCount;
      const formulasCopy = range.formulas.slice(0);
      let currentCounter = 0;
      initProgress(maxRows * maxCols, curSheet.name ? curSheet.name : `Sheet ${sheetCounter}`);

      const promiseAr: Array<Promise<void>> = [];

      for (let j = 0; j < maxRows; j++) {
        for (let k = 0; k < maxCols; k++) {

          const promise = new Promise<void>((resolve, reject) => {
            try {
              if (cancellationToken.cancelled ) {
                stoppedByUser = true;
                reject("cancelled");
              }

              const curJ = j; const curK = k;
              const res = callback(range.values[curJ][curK], formulasCopy[curJ][curK], loadContextMode, context);

              if (res instanceof Promise) {
                res.then((value) => {
                  formulasCopy[curJ][curK] = value;
                  resolve(null);
                });
              } else {
                formulasCopy[curJ][curK] = res;
                resolve(null);
              }

              currentCounter++;
              progress(currentCounter);
            } catch (e) {
              console.error(e);
              reject(e);
            }
          });
          promiseAr.push(limit(() => promise));
        }
      }

      await Promise.all(promiseAr);

      range.formulas = formulasCopy;
      sheetCounter++;
    }
    if (endProgress) endProgress(stoppedByUser);
  }
}
