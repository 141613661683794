export enum AccountNature {
  Assets = 0,
  Liabilities = 1,
  Cost = 2,
  Income = 3,
  OffBalanceSheet = 4,
}

export enum AccountGroupingType  {
  Class =0,
  Group =1,
  SubClass =2,
  SubGroup =3,
  Account =4
}


export interface PublicAccountDTO
{
  ID: string;
  AccountNumber: string;
  Name: string;
  CurrencyCode: string;
  TaxName: string;
  TaxID: string;
  DecimalPlaces: number;
  DisplayFormatedString: string;
  AccountNature: AccountNature;
  AccountGroupingType: AccountGroupingType;
}
