import { combineReducers } from "@reduxjs/toolkit";
import { AuthStateInfos, MainStateInfos } from "@taskpane/types";

import { authReducer } from "./auth.reducer";
import { mainReducer } from "./main.reducer";

export interface StoreState {
  auth: AuthStateInfos;
  main: MainStateInfos;
}

export const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  main: mainReducer,
});
