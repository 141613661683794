import { useContext } from 'react';
import {IServicesInstances, ServicesContext} from '../../services/servicesContext';

export const useServices = () => {
  const context = useContext<IServicesInstances>(ServicesContext);
  if (context === undefined) {
    throw new Error(`ServicesContext was not provided. Make sure your component is a child of Services`);
  }
  return context;
};
