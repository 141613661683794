// source : https://usehooks-typescript.com/react-hook/use-debounce
import { useEffect, useState } from "react";

export function useDebounce<T>(unBouncedValue: T, delay = 500): T {
  const [value, setValue] = useState<T>(unBouncedValue);

  useEffect(() => {
    const timer = setTimeout(() => setValue(unBouncedValue), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [unBouncedValue, delay]);

  return value;
}
