import "./styles.css";

import React, {  ReactNode } from "react";

interface FooterProps {
  children?: ReactNode;
}

// const iconStyle: CSSProperties = {
//   height: "24px",
//   width: "24px",
//   backgroundColor: "transparent",
// };

export const Footer = ({ children }: FooterProps) => (
  <div className="top-border bg-white">
    {children}
    <div className="d-flex justify-content-center m-2">
      <span>
        Powered by
      </span>
      <span className="ms-2 me-2">
        <img src="assets/logoGit.png"/>
      </span>
      <span className="ms-1">
        <span className="pl-2"> v{process.env.WEBAPP_VERSION}</span>
      </span>
    </div>
  </div>
);
