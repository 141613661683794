import {
  ComputeFormulasAction,
  LoadAccountListAction,
  LoadCompanyListAction, LoadCurrencyList,
  UpdateDisplayFormulasAction
} from "@taskpane/core/actions";
import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";
import {setAccountList, setCompanyList, setCurrencyList, updateDisplayFormulas} from "@taskpane/core/actionCreators";
import FormulasModeClass from "../../features/formulasModeClass";
import {FormulasMode} from "@taskpane/utils";
import {MainActionTypes} from "@taskpane/core/actionTypes";
import {ApiService} from "@taskpane/core/services";
import ComputeFormulas from "../../features/computeFormulas";


function* loadCompanyListSaga({payload}: LoadCompanyListAction) {
  const fetch = async (payload) => {
    const httpResponse = await new ApiService().getFiscalYearsAsync();
    return httpResponse.parsedBody;
  }
  const list = yield call(fetch, payload);
  yield put(setCompanyList(list));
}

function* loadAccountListSaga({ payload }: LoadAccountListAction) {
  const fetch = async (payload) => {
    const httpResponse = await new ApiService().getCOA(payload.fiscalYearId);
    return httpResponse.parsedBody;
  }
  const list = yield call(fetch, payload);
  yield put(setAccountList(list));
}

function* loadCurrencyListSaga({ payload }: LoadCurrencyList) {
  const fetch = async (payload) => {
    const httpResponse = await new ApiService().getCurrencies(payload.fiscalYearId);
    return httpResponse.parsedBody;
  }
  const list = yield call(fetch, payload);
  yield put(setCurrencyList(list));
}

// function* computeFormulasSaga({}: ComputeFormulasAction) {
//   const { displayFormulas } = yield select(state => state.main);
//   const computeFormulas = async (payload) => {
//     const computeFormulas = new ComputeFormulas();
//     await computeFormulas.compute(displayFormulas ? FormulasMode.Formulas: FormulasMode.Amount);
//   }
//   yield call(computeFormulas, null);
// }

function* watchMain() {
  yield takeEvery(MainActionTypes.LOAD_COMPANY_LIST, loadCompanyListSaga);
  yield takeEvery(MainActionTypes.LOAD_ACCOUNT_LIST, loadAccountListSaga);
  yield takeEvery(MainActionTypes.LOAD_CURRENCY_LIST, loadCurrencyListSaga);
}

export function* mainSaga() {
  yield all([fork(watchMain)]);
}
