import {PublicFiscalYearDTO} from "./publicFiscalYearDto";
import {PublicAccountDTO} from "./publicAccountDto";
import {PublicCurrencyDTO} from "./publicCurrencyDto";

export interface MainStateInfos {
  displayFormulas?: boolean;
  companyList?: Array<PublicFiscalYearDTO>;
  accountList?: Array<PublicAccountDTO>;
  currencyList?: Array<PublicCurrencyDTO>;
}
