import {ICalloutProps, IIconStyles, IStackTokens} from "@fluentui/react";

export const infoLabelStackTokens: IStackTokens = {
  childrenGap: 4,
  maxWidth: 300,
};

export const infoLabelIconStyles: Partial<IIconStyles> = {
  root: { cursor: "pointer", marginBottom: 5, marginTop: 4, color: "blue" },
};

export const dialogStyles = { main: { maxWidth: 450 } };
