import {GetApiKeyAction, SignInAction} from "../actions/auth.actions";
import {all, call, fork, put, select, takeLatest} from "redux-saga/effects";
import {
  getApiKeySuccess,
  signInFailure,
  signInRequest,
  signInSuccess,
  signOut
} from "../actionCreators/auth.actionCreators";
import {ApiKeyRequestDTO, ApiKeyRequestResult, ApiKeyRequestStatusDTO} from "../../@types";
import {AuthService} from "../services/auth.service";
import {AuthActionTypes} from "../actionTypes";
import {getAuthSelector} from "../reducers/auth.reducer";


function* signIn( {payload}: SignInAction) {
  try {
    yield put(signInRequest());
    const authSvc = new AuthService();

    const postApiKeyRequest = async (payload: boolean) => {
      return await authSvc.postApiKeyRequest();
    }
    const data: ApiKeyRequestDTO = yield call(postApiKeyRequest, payload);

    yield put(signInSuccess({
      apiKeyRequestId: data.RequestID,
      authUrl: data.AuthUrl
      }
    ));
  } catch (error) {
    yield put(signInFailure(error));
  }
}

function* watchAuth() {
  yield takeLatest(AuthActionTypes.SIGN_IN, signIn);
  yield takeLatest(AuthActionTypes.GET_API_KEY, getApiKey);
}

function* getApiKey( {payload}: GetApiKeyAction) {
  try {
    let authState = yield select(getAuthSelector);
    const authSvc = new AuthService();

    const getApiKeyRequestStatus = async (payload: boolean) => {

      return (await authSvc.getApiKeyRequestStatus(authState.apiKeyRequestId)).parsedBody;

    }
    const data: ApiKeyRequestStatusDTO = yield call(getApiKeyRequestStatus, payload);

    if (data.RequestResult === ApiKeyRequestResult.OK) {
      console.log("get api key success");
      yield put(getApiKeySuccess({
          apiKey: data.ApiKey,
          tenantUserEmail: data.TenantUserEmail,
          tenant: data.TenantName,
          tenantUser: data.TenantUserName,
        }
      ));
    }

  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* authSaga() {
  yield all([fork(watchAuth)]);
}

