import moment from 'moment';

export const Endpoints: any =
{

  createApiKeyRequest: () => "public/api-key",
  getApiKeyRequestStatus: (apiKeyRequestId: string) => `public/api-key/${apiKeyRequestId}/status`,
  getFiscalYears: () => "public/fiscalyear",
  getFiscalYear: (fiscalYearId: string) => `public/fiscalyear/${fiscalYearId}`,
  getCOA: (fiscalYearId: string) => `public/fiscalyear/${fiscalYearId}/coa`,
  getTrialBalance: (fiscalYearId: string) => `public/fiscalyear/${fiscalYearId}/trialbalance`,
  getTrialBalanceMvts: (fiscalYearId: string ) => `public/fiscalyear/${fiscalYearId}/trialbalance/mvts`,
  getCurrencies: (fiscalYearId: string ) => `public/fiscalyear/${fiscalYearId}/currencies`,
  getBalanceSheetTree: (fiscalYearId: string) => `public/fiscalyear/${fiscalYearId}/balancesheettree`,
  getProfitAndLossTree: (fiscalYearId: string ) => `public/fiscalyear/${fiscalYearId}/profitandlosstree`,
  storageItemDownload: (objectId: string) => `public/storageitems/${objectId}`
}

export const ViewsEndpoints = {
  getAccountMovements: (accountId: string,
                        fromDate: Date,
                        toDate: Date,
                        includeProvisionalEntries) => `TrialBalance/GetAccountMovements?accountID=${accountId}&fromDate=${moment(fromDate).format("YYYY-MM-DD")}&toDate=${moment(toDate).format("YYYY-MM-DD")}&IncludeProvisionalEntries=${includeProvisionalEntries.toString()}`,
}
