import ChoiceFormulaWrapper from "../../features/choiceFormulaWrapper";
import {FormulasMode} from "@taskpane/utils";
import FormulaFactory from "../../features/formulaFactory";
import {ICellMvtsLinkService} from "./interfaces/ICellMvtsLink.service";

export class CellMvtsLinkService implements ICellMvtsLinkService {
  async putLinkIntoCell(activeCell: Excel.Range, formula: string) {
    try {
      const context = activeCell.context;
      const wrapper = new ChoiceFormulaWrapper();
      const res = wrapper.unwrap(formula);
      if (res && res.formula) {
        const originalMode = res.mode;
        let changedFormulaMode = false;
        if (originalMode !== FormulasMode.Formulas) {
          activeCell.formulas = [[ wrapper.wrap(FormulasMode.Formulas, res.value, res.formula)]];
          activeCell = context.workbook.getActiveCell();
          activeCell.load("formulas, values");
          await context.sync();
          changedFormulaMode = true;
        }

        const objectFormula = new FormulaFactory().getFormulaInstance(activeCell.values[0][0]);

        if (changedFormulaMode) {
          activeCell.formulas = [[ wrapper.wrap(originalMode, res.value, res.formula)]];
        }
        if (objectFormula) {
          activeCell.hyperlink = {
            textToDisplay: formula,
            address: objectFormula.getDisplayMvtsLink(),
            screenTip: "display movements",
          }
          await context.sync();
        }
      }
    } catch (e) {
      console.error('CellMvtsLinkService.putLinkIntoCell: error ', e);
      throw new Error(e);
    }
  }
}
