import {
  IProgressIndicatorProps,
  IShimmerProps,
  ISpinnerProps,
  ProgressIndicator,
  Shimmer,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import React, { memo } from "react";

export type LoaderType = "shimmer" | "progress" | "spinner";

export interface SpinnerProps extends ISpinnerProps {
  loader: LoaderType;
}
export interface ShimmerProps extends IShimmerProps {
  loader: LoaderType;
}
export interface ProgressProps extends IProgressIndicatorProps {
  loader: LoaderType;
}

export const Loader = memo((props: SpinnerProps | ShimmerProps | ProgressProps) => {
  switch (props.loader) {
    case "spinner":
      return <Spinner {...(props as SpinnerProps)} />;
    case "progress":
      return <ProgressIndicator {...(props as ProgressProps)} />;
    case "shimmer":
      return <Shimmer {...(props as ShimmerProps)} />;
    default:
      return <Spinner {...(props as SpinnerProps)} />;
  }
});

export const ViewLoader = ({ ...rest }: ISpinnerProps) => (
  <div className="h-100 row align-items-center">
    <Spinner size={SpinnerSize.large} {...rest} />
  </div>
);
